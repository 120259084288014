body {
  overflow-x:hidden;
  background:#FFF;
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: left;
  }
  .clearfix {
      clear:both;
  }
  .big-row {
    max-width:rem-calc(1280);
    margin:0 auto;
  }
  .row {
    @include breakpoint(large) {
      padding:rem-calc(0 20);
    }
  }
  #content {
    position:relative;
    z-index:0;
  }
  &.expanded {
    .the-mobile-menu {
      display:block;
    }
    #content,
    .footer {
      display:none;
      @include breakpoint(large) {
        display:block;
      }
    }
    .mobile-menu-btn {
      position: absolute;
      top: rem-calc(50);
      right: rem-calc(50);

      .menu-button {
        display: inline-block;
        .hamburger {
          width: rem-calc(50);
          height: rem-calc(50);
          position: relative;
          &:hover {
            span {
              border-color:#BFDB08;
            }
          }

          span {
            border-top: rem-calc(3) solid #3b3b41;
            display: block;
            opacity: 1;
            position: absolute;
            width: 100%;
            top: 0;
            -webkit-transition: all .6s ease;
            -moz-transition: all .6s ease;
            -o-transition: all .6s ease;
            transition: all .6s ease;
            &:first-of-type {
              display: none;
            }
            &:nth-of-type(2) {
              -ms-transform: rotate(45deg); /* IE 9 */
              -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
              transform: rotate(45deg);
              top: rem-calc(14);
            }
            &:last-of-type {
              -ms-transform: rotate(-45deg); /* IE 9 */
              -webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
              transform: rotate(-45deg);
              top: rem-calc(14);
            }
          }
        }
      }
    }
  }
  .btn {
    border-radius:rem-calc(40);
    display:inline-block;
    height:rem-calc(70);
    line-height:rem-calc(66);
    border:rem-calc(2) solid #fff;
    padding:rem-calc(0 32);
    @include roboto(700);
    font-size:rem-calc(25);
    color:#1E1E1E;
    transition:all 0.3s linear;
    @include breakpoint(large) {
      font-size:rem-calc(15);
      border-radius:rem-calc(30);
      height:rem-calc(40);
      line-height:rem-calc(36);
      padding:rem-calc(0 24);
    }
    &:hover {
      background:#fff;
      color:#646464;
    }
  }
}

.compat-object-fit {
  background-size: cover;
  background-position: center center;
  background-repeat:no-repeat;
  img { // hide image if object fit is not supported - opacity to 0 for the link area
    opacity: 0;
  }
}