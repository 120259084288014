.intro {
  position:relative;
  z-index:1;

  @include breakpoint(large) {

  }
  .image {
    background-position:center right;
    background-size:cover;
    background-repeat:no-repeat;
    height:rem-calc(520);
    position:relative;
    width:100vw;
    @include breakpoint(large) {
      height:rem-calc(760);
    }
  }
  .main-ctas {

    @include breakpoint(large) {
      position:absolute;
      top:50%;
      left:0;
      right:0;
      z-index:5;
      transform:translateY(-50%);
      display:flex;
      width:100%;
    }
    .patient {
      background:rgba(15,98,115,0.9);
    }
    .provider {
      background:rgba(118,118,118,0.9);
    }
    .patient,
    .provider {
      padding:rem-calc(65);
      text-align:center;
      @include breakpoint(large) {
        padding:rem-calc(45);
        width:50%;
      }
      p {
        margin:rem-calc(35) auto 0 auto;
        font-size:rem-calc(28);
        line-height:rem-calc(40);
        @include roboto(400);
        color:#fff;
        @include breakpoint(large) {
          width:rem-calc(370);
          font-size:rem-calc(21);
          line-height:rem-calc(30);
          letter-spacing:rem-calc(0.1);
          margin:rem-calc(25) auto 0 auto;
        }
      }
      .btn {
        margin:0;
        display:block;
        height:rem-calc(80);
        line-height:rem-calc(74);
        background:#fff;
        text-align:center;
        @include roboto(500);
        font-size:rem-calc(28);
        color:#0F6273;
        width:100%;
        border:rem-calc(2) solid #fff;
        @include breakpoint(large) {
          width:rem-calc(430);
          display:inline-block;
          margin:rem-calc(0) auto;
          height:rem-calc(60);
          line-height:rem-calc(54);
          font-size:rem-calc(22);
        }
        &:hover {
          background:#fff;
          color:rgba(118,118,118,1);
        }
      }
    }
  }
}