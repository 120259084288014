#google_translate_element {
    
    .goog-te-gadget-simple {
        border: none;
        padding: 0;
        margin: 0;
        display: block;
        position: relative;
        background: transparent;
        
        a.goog-te-menu-value {
          display: block;
          position: relative;
          color: #1e1e1e !important;
          height:rem-calc(30);
          line-height:rem-calc(22);
          margin: rem-calc(0 0 0 22);
          padding:rem-calc(0 7 0 0);
          font-size: rem-calc(14);
          letter-spacing:rem-calc(0.3);
            
            & > span {
                display: none;
                
                &:first-of-type {
                    display: inline-block;
                }
            }
            
            &:after {
              content: "\e906";
              font-family:'icomoon';
                border:none;
                position:absolute;
                top:rem-calc(1);
                right:0;
                font-size:rem-calc(5);
              }
            
            &:hover {
                &:after {
                  content: "\e905";
                }
                color: #4e4e4e !important;
            }
        }
    }
    
    .goog-te-gadget-icon {
        display: none;
    }
}