body {
  &.high-contrast {
    img {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
    .btn {
      background:#000 !important;
      border-color:#000 !important;
      &:hover {
        background:#fff !important;
        color:#000 !important;
      }
    }
    .intro {
      .image {
        filter: grayscale(100%);
      }
    }
    .top-menu {
      background:#000;
      .top-menu-left,
      .top-menu-right {
        > ul {
          > li {
            > a {
              color:#fff;
              &:hover {
                color:#fff;
              }
            }
            .goog-te-menu-value {
              span {
                color:#fff;
              }
              &:after {
                color:#fff;
              }

            }

          }
        }
      }
    }

    .footer {
      background:#000;
        .footer-links {
          ul {
            li {
              a {
                color:#fff;
                &:hover {
                  color:#fff;
                }
              }
            }
          }
          span {
            color:#fff;
          }
        }
    }
  }
}