.image-banner {
  height:rem-calc(360);

  .big-row {
    position:relative;


  }
  img {
    width:100%;
    height:rem-calc(360);
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:1;
    object-fit:cover;
    object-position:right center;
    @include breakpoint(large) {

    }
  }
  .title {
    display:flex;
    align-items:center;
    position:relative;
    z-index:2;
    height:rem-calc(360);
    h1 {
      background:rgba(118,118,118,0.95);
      position:absolute;
      left:rem-calc(-60);
      padding:rem-calc(0 45 0 105);
      text-align:right;
      @include roboto(700);
      font-size:rem-calc(34);
      color:#fff;
      height:rem-calc(80);
      line-height:rem-calc(80);
    }
  }
}