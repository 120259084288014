@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=48em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 74.375rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' '; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -1.25rem;
    margin-left: -1.25rem; }
    @media print, screen and (min-width: 48em) {
      .row .row {
        margin-right: -0.625rem;
        margin-left: -0.625rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.625rem;
        margin-left: -0.625rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 1.25rem;
    padding-left: 1.25rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .row.gutter-x-large > .column, .row.gutter-x-large > .columns {
    padding-right: 1.09375rem;
    padding-left: 1.09375rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.09375rem;
  padding-left: 1.09375rem; }
  @media print, screen and (min-width: 48em) {
    .column, .columns {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 1.25rem;
  padding-left: 1.25rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 48em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 2.5rem;
  margin-bottom: 2.1875rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 48em) {
    .column-block {
      margin-bottom: 1.25rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }
  .column-block > :last-child {
    margin-bottom: 0; }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    line-height: 0;
    color: #cacaca; }

h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 48em) {
  h1 {
    font-size: 3rem; }
  h2 {
    font-size: 2.5rem; }
  h3 {
    font-size: 1.9375rem; }
  h4 {
    font-size: 1.5625rem; }
  h5 {
    font-size: 1.25rem; }
  h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #005565;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #004957; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 74.375rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr {
  border-bottom: 1px dotted #0a0a0a;
  color: #0a0a0a;
  cursor: help; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 48em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #1779ba;
  color: #fefefe; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #14679e;
    color: #fefefe; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #1779ba;
    color: #fefefe; }
    .button.primary:hover, .button.primary:focus {
      background-color: #126195;
      color: #fefefe; }
  .button.secondary {
    background-color: #767676;
    color: #fefefe; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #5e5e5e;
      color: #fefefe; }
  .button.success {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button.warning {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button.alert {
    background-color: #cc4b37;
    color: #fefefe; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #fefefe; }
  .button.hollow {
    border: 1px solid #1779ba;
    color: #1779ba; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #0c3d5d;
      color: #0c3d5d; }
    .button.hollow.primary {
      border: 1px solid #1779ba;
      color: #1779ba; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #0c3d5d;
        color: #0c3d5d; }
    .button.hollow.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #1779ba;
      color: #fefefe; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #1779ba;
        color: #fefefe; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #767676;
        color: #fefefe; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #0a0a0a; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #0a0a0a; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #fefefe; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fefefe transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #1779ba; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #1779ba; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #767676; }
  .button.dropdown.hollow.success::after {
    border-top-color: #3adb76; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #cc4b37; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a; }

.input-group {
  display: table;
  width: 100%;
  margin-bottom: 1rem; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  width: 1%;
  height: 100%; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  height: 2.5rem; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  width: 1%;
  height: 100%; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: 2.5rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

.input-group .input-group-button {
  display: table-cell; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem;
    background: #fefefe; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1rem;
  line-height: normal;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #f9ecea; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #1779ba; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0 0 0 0; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #e6e6e6; }
  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+'; }
  .is-active > .accordion-title::before {
    content: '\2013'; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #0a0a0a; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #e6e6e6; }

.is-accordion-submenu-parent > a {
  position: relative; }
  .is-accordion-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1rem; }

.is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%; }

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #1779ba;
  color: #fefefe; }
  .badge.primary {
    background: #1779ba;
    color: #fefefe; }
  .badge.secondary {
    background: #767676;
    color: #fefefe; }
  .badge.success {
    background: #3adb76;
    color: #0a0a0a; }
  .badge.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .badge.alert {
    background: #cc4b37;
    color: #fefefe; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' '; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.6875rem;
    color: #0a0a0a;
    cursor: default; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      top: 1px;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #cacaca; }
  .breadcrumbs a {
    color: #1779ba; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #cacaca;
    cursor: not-allowed; }

.button-group {
  margin-bottom: 1rem;
  font-size: 0; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' '; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded {
    margin-right: -1px; }
    .button-group.expanded::before, .button-group.expanded::after {
      display: none; }
    .button-group.expanded .button:first-child:last-child {
      width: 100%; }
    .button-group.expanded .button:first-child:nth-last-child(2), .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button {
      display: inline-block;
      width: calc(50% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(2):last-child, .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(3), .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button {
      display: inline-block;
      width: calc(33.33333% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(3):last-child, .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(4), .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button {
      display: inline-block;
      width: calc(25% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(4):last-child, .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(5), .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button {
      display: inline-block;
      width: calc(20% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(5):last-child, .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(6), .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button {
      display: inline-block;
      width: calc(16.66667% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(6):last-child, .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button:last-child {
        margin-right: -6px; }
  .button-group.primary .button {
    background-color: #1779ba;
    color: #fefefe; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #126195;
      color: #fefefe; }
  .button-group.secondary .button {
    background-color: #767676;
    color: #fefefe; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #5e5e5e;
      color: #fefefe; }
  .button-group.success .button {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button-group.alert .button {
    background-color: #cc4b37;
    color: #fefefe; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #a53b2a;
      color: #fefefe; }
  .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
    width: 100%; }
    .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
      margin-bottom: 0; }
  @media print, screen and (min-width: 48em) {
    .button-group.stacked-for-small .button {
      width: auto;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button {
      width: auto;
      margin-bottom: 0; } }
  @media screen and (max-width: 47.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 0;
  background-color: white;
  color: #0a0a0a; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #d7ecfa;
    color: #0a0a0a; }
  .callout.secondary {
    background-color: #eaeaea;
    color: #0a0a0a; }
  .callout.success {
    background-color: #e1faea;
    color: #0a0a0a; }
  .callout.warning {
    background-color: #fff3d9;
    color: #0a0a0a; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #0a0a0a; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.card {
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background: #fefefe;
  box-shadow: none;
  overflow: hidden;
  color: #0a0a0a; }
  .card > :last-child {
    margin-bottom: 0; }

.card-divider {
  padding: 1rem;
  background: #e6e6e6; }
  .card-divider > :last-child {
    margin-bottom: 0; }

.card-section {
  padding: 1rem; }
  .card-section > :last-child {
    margin-bottom: 0; }

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #0a0a0a; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  margin: 0;
  list-style-type: none; }
  .menu > li {
    display: table-cell;
    vertical-align: middle; }
    [data-whatinput='mouse'] .menu > li {
      outline: 0; }
  .menu > li > a {
    display: block;
    padding: 0.7rem 1rem;
    line-height: 1; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    vertical-align: middle; }
    .menu > li > a img + span,
    .menu > li > a i + span,
    .menu > li > a svg + span {
      vertical-align: middle; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    margin-right: 0.25rem;
    display: inline-block; }
  .menu > li, .menu.horizontal > li {
    display: table-cell; }
  .menu.expanded {
    display: table;
    width: 100%;
    table-layout: fixed; }
    .menu.expanded > li:first-child:last-child {
      width: 100%; }
  .menu.vertical > li {
    display: block; }
  @media print, screen and (min-width: 48em) {
    .menu.medium-horizontal > li {
      display: table-cell; }
    .menu.medium-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.medium-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.medium-vertical > li {
      display: block; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal > li {
      display: table-cell; }
    .menu.large-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.large-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.large-vertical > li {
      display: block; } }
  .menu.simple li {
    display: inline-block;
    vertical-align: top;
    line-height: 1; }
  .menu.simple a {
    padding: 0; }
  .menu.simple li {
    margin-left: 0;
    margin-right: 1rem; }
  .menu.simple.align-right li {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.align-right::before, .menu.align-right::after {
    display: table;
    content: ' '; }
  .menu.align-right::after {
    clear: both; }
  .menu.align-right > li {
    float: right; }
  .menu.icon-top > li > a {
    text-align: center; }
    .menu.icon-top > li > a img,
    .menu.icon-top > li > a i,
    .menu.icon-top > li > a svg {
      display: block;
      margin: 0 auto 0.25rem; }
  .menu.icon-top.vertical a > span {
    margin: auto; }
  .menu.nested {
    margin-left: 1rem; }
  .menu .active > a {
    background: #1779ba;
    color: #fefefe; }
  .menu.menu-bordered li {
    border: 1px solid #e6e6e6; }
    .menu.menu-bordered li:not(:first-child) {
      border-top: 0; }
  .menu.menu-hover li:hover {
    background-color: #e6e6e6; }

.menu-text {
  padding-top: 0;
  padding-bottom: 0;
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit; }

.menu-centered {
  text-align: center; }
  .menu-centered > .menu {
    display: inline-block;
    vertical-align: top; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #fefefe;
    box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
    content: ''; }
  .menu-icon:hover::after {
    background: #cacaca;
    box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #0a0a0a;
    box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #8a8a8a;
    box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fefefe;
  transition: transform 0.15s linear; }
  .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }

.drilldown-submenu-cover-previous {
  min-height: 100%; }

.is-drilldown-submenu-parent > a {
  position: relative; }
  .is-drilldown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba;
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem; }

.js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  display: block;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-size: 1rem; }
  .dropdown-pane.is-open {
    visibility: visible; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #005565 transparent transparent;
  right: 5px;
  margin-top: -3px; }

[data-whatinput='mouse'] .dropdown.menu a {
  outline: 0; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #005565 transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #005565; }

@media print, screen and (min-width: 48em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #005565 transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #005565 transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #005565; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #005565 transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #005565 transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #005565; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fefefe; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #005565 transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #005565; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #1779ba;
  color: #fefefe; }
  .label.primary {
    background: #1779ba;
    color: #fefefe; }
  .label.secondary {
    background: #767676;
    color: #fefefe; }
  .label.success {
    background: #3adb76;
    color: #0a0a0a; }
  .label.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .label.alert {
    background: #cc4b37;
    color: #fefefe; }

.media-object {
  display: block;
  margin-bottom: 1rem; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 47.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      display: block; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  display: table-cell;
  vertical-align: top; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.middle {
    vertical-align: middle; }
  .media-object-section.bottom {
    vertical-align: bottom; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(254, 254, 254, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 1;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-overlap {
    z-index: 10; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 1;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 10; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  transform: translateX(-250px);
  overflow-y: auto; }
  .position-left.is-open ~ .off-canvas-content {
    transform: translateX(250px); }
  .position-left.is-transition-push::after {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-left.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-right {
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  transform: translateX(250px);
  overflow-y: auto; }
  .position-right.is-open ~ .off-canvas-content {
    transform: translateX(-250px); }
  .position-right.is-transition-push::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-right.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(-250px);
  overflow-x: auto; }
  .position-top.is-open ~ .off-canvas-content {
    transform: translateY(250px); }
  .position-top.is-transition-push::after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-top.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(250px);
  overflow-x: auto; }
  .position-bottom.is-open ~ .off-canvas-content {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 100%;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-bottom.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.off-canvas-content {
  transition: transform 0.5s ease;
  backface-visibility: hidden; }

@media print, screen and (min-width: 48em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 250px; } }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(10, 10, 10, 0.5);
  color: #fefefe; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fefefe; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(10, 10, 10, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.1rem;
    border-radius: 50%;
    background-color: #cacaca; }
    .orbit-bullets button:hover {
      background-color: #8a8a8a; }
    .orbit-bullets button.is-active {
      background-color: #8a8a8a; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' '; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 48em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #0a0a0a; }
    .pagination a:hover,
    .pagination button:hover {
      background: #e6e6e6; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #1779ba;
    color: #fefefe;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #0a0a0a; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca; }
  .progress.primary .progress-meter {
    background-color: #1779ba; }
  .progress.secondary .progress-meter {
    background-color: #767676; }
  .progress.success .progress-meter {
    background-color: #3adb76; }
  .progress.warning .progress-meter {
    background-color: #ffae00; }
  .progress.alert .progress-meter {
    background-color: #cc4b37; }

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #1779ba; }

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fefefe;
  white-space: nowrap; }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out; }
  .slider-fill.is-dragging {
    transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #1779ba;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation; }
  [data-whatinput='mouse'] .slider-handle {
    outline: 0; }
  .slider-handle:hover {
    background-color: #14679e; }
  .slider-handle.is-dragging {
    transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1); }
  .slider.vertical .slider-fill {
    top: 0;
    width: 0.5rem;
    max-height: 100%; }
  .slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateX(-50%); }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 48em) {
    .reveal {
      min-height: 0; } }
  .reveal .column, .reveal .columns {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 48em) {
    .reveal {
      width: 600px;
      max-width: 74.375rem; } }
  @media print, screen and (min-width: 48em) {
    .reveal .reveal {
      right: auto;
      left: auto;
      margin: 0 auto; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 48em) {
    .reveal.tiny {
      width: 30%;
      max-width: 74.375rem; } }
  @media print, screen and (min-width: 48em) {
    .reveal.small {
      width: 50%;
      max-width: 74.375rem; } }
  @media print, screen and (min-width: 48em) {
    .reveal.large {
      width: 90%;
      max-width: 74.375rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 47.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fefefe;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #fefefe;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #1779ba; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.625rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.75rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

table {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  thead,
  tbody,
  tfoot {
    border: 1px solid #f1f1f1;
    background-color: #fefefe; }
  caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold; }
  thead {
    background: #f8f8f8;
    color: #0a0a0a; }
  tfoot {
    background: #f1f1f1;
    color: #0a0a0a; }
  thead tr,
  tfoot tr {
    background: transparent; }
  thead th,
  thead td,
  tfoot th,
  tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold;
    text-align: left; }
  tbody th,
  tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  tbody tr:nth-child(odd) {
    background-color: #f1f1f1; }
  table.unstriped tbody {
    background-color: #fefefe; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f1f1f1;
      background-color: #fefefe; }

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f3f3f3; }

table.hover tfoot tr:hover {
  background-color: #ececec; }

table.hover tbody tr:hover {
  background-color: #f9f9f9; }

table.hover:not(.unstriped) tr:nth-of-type(odd):hover {
  background-color: #ececec; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #fefefe;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' '; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #1779ba; }
  .tabs.primary > li > a {
    color: #fefefe; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #1673b1; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    font-size: 0.75rem;
    line-height: 1;
    color: #1779ba; }
    .tabs-title > a:hover {
      background: #fefefe;
      color: #1468a0; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #e6e6e6;
      color: #1779ba; }

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #fefefe;
  color: #0a0a0a;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }
  .tabs-panel[aria-hidden="false"] {
    display: block; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(23, 121, 186, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #0a0a0a;
  color: #fefefe; }
  .title-bar::before, .title-bar::after {
    display: table;
    content: ' '; }
  .title-bar::after {
    clear: both; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left {
  float: left; }

.title-bar-right {
  float: right;
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #0a0a0a;
  font-size: 80%;
  color: #fefefe; }
  .tooltip::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #0a0a0a;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0a0a0a transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0a0a0a;
    top: 50%;
    bottom: auto;
    left: 100%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0a0a0a transparent transparent;
    top: 50%;
    right: 100%;
    bottom: auto;
    left: auto;
    transform: translateY(-50%); }

.top-bar {
  padding: 0.5rem; }
  .top-bar::before, .top-bar::after {
    display: table;
    content: ' '; }
  .top-bar::after {
    clear: both; }
  .top-bar,
  .top-bar ul {
    background-color: #e6e6e6; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    width: 100%; }
  @media print, screen and (min-width: 48em) {
    .top-bar .top-bar-left,
    .top-bar .top-bar-right {
      width: auto; } }
  @media screen and (max-width: 63.9375em) {
    .top-bar.stacked-for-medium .top-bar-left,
    .top-bar.stacked-for-medium .top-bar-right {
      width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large .top-bar-left,
    .top-bar.stacked-for-large .top-bar-right {
      width: 100%; } }

.top-bar-title {
  display: inline-block;
  float: left;
  padding: 0.5rem 1rem 0.5rem 0; }
  .top-bar-title .menu-icon {
    bottom: 2px; }

.top-bar-left {
  float: left; }

.top-bar-right {
  float: right; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 47.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 48em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 48em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 47.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 47.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' '; }

.clearfix::after {
  clear: both; }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  animation-name: shake-7; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.spin-cw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.spin-ccw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(1turn); } }

.wiggle {
  animation-name: wiggle-7deg; }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms; }

.infinite {
  animation-iteration-count: infinite; }

.slow {
  animation-duration: 750ms !important; }

.fast {
  animation-duration: 250ms !important; }

.linear {
  animation-timing-function: linear !important; }

.ease {
  animation-timing-function: ease !important; }

.ease-in {
  animation-timing-function: ease-in !important; }

.ease-out {
  animation-timing-function: ease-out !important; }

.ease-in-out {
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  animation-delay: 300ms !important; }

.long-delay {
  animation-delay: 700ms !important; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?mic3io");
  src: url("../fonts/icomoon.eot?mic3io#iefix") format("embedded-opentype"), url("../fonts/icomoon.woff2?mic3io") format("woff2"), url("../fonts/icomoon.ttf?mic3io") format("truetype"), url("../fonts/icomoon.woff?mic3io") format("woff"), url("../fonts/icomoon.svg?mic3io#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-search-icon:before {
  content: "\e900";
  color: #767676; }

.icon-right-arrow:before {
  content: "\e901"; }

.icon-instagram:before {
  content: "\e902"; }

.icon-facebook:before {
  content: "\e903"; }

.icon-twitter:before {
  content: "\e904"; }

.icon-up-arrow:before {
  content: "\e905"; }

.icon-down-arrow:before {
  content: "\e906"; }

.icon-pause-button:before {
  content: "\e907"; }

.icon-play-button:before {
  content: "\e908"; }

.icon-small-right-arrow:before {
  content: "\e909"; }

.icon-linkedin:before {
  content: "\e90a"; }

body {
  overflow-x: hidden;
  background: #FFF; }
  body .column:last-child:not(:first-child), body .columns:last-child:not(:first-child), body .columns:last-child:not(:first-child) {
    float: left; }
  body .clearfix {
    clear: both; }
  body .big-row {
    max-width: 80rem;
    margin: 0 auto; }
  @media print, screen and (min-width: 64em) {
    body .row {
      padding: 0 1.25rem; } }
  body #content {
    position: relative;
    z-index: 0; }
  body.expanded .the-mobile-menu {
    display: block; }
  body.expanded #content,
  body.expanded .footer {
    display: none; }
    @media print, screen and (min-width: 64em) {
      body.expanded #content,
      body.expanded .footer {
        display: block; } }
  body.expanded .mobile-menu-btn {
    position: absolute;
    top: 3.125rem;
    right: 3.125rem; }
    body.expanded .mobile-menu-btn .menu-button {
      display: inline-block; }
      body.expanded .mobile-menu-btn .menu-button .hamburger {
        width: 3.125rem;
        height: 3.125rem;
        position: relative; }
        body.expanded .mobile-menu-btn .menu-button .hamburger:hover span {
          border-color: #BFDB08; }
        body.expanded .mobile-menu-btn .menu-button .hamburger span {
          border-top: 0.1875rem solid #3b3b41;
          display: block;
          opacity: 1;
          position: absolute;
          width: 100%;
          top: 0;
          -webkit-transition: all .6s ease;
          -moz-transition: all .6s ease;
          -o-transition: all .6s ease;
          transition: all .6s ease; }
          body.expanded .mobile-menu-btn .menu-button .hamburger span:first-of-type {
            display: none; }
          body.expanded .mobile-menu-btn .menu-button .hamburger span:nth-of-type(2) {
            -ms-transform: rotate(45deg);
            /* IE 9 */
            -webkit-transform: rotate(45deg);
            /* Chrome, Safari, Opera */
            transform: rotate(45deg);
            top: 0.875rem; }
          body.expanded .mobile-menu-btn .menu-button .hamburger span:last-of-type {
            -ms-transform: rotate(-45deg);
            /* IE 9 */
            -webkit-transform: rotate(-45deg);
            /* Chrome, Safari, Opera */
            transform: rotate(-45deg);
            top: 0.875rem; }
  body .btn {
    border-radius: 2.5rem;
    display: inline-block;
    height: 4.375rem;
    line-height: 4.125rem;
    border: 0.125rem solid #fff;
    padding: 0 2rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 1.5625rem;
    color: #1E1E1E;
    transition: all 0.3s linear; }
    @media print, screen and (min-width: 64em) {
      body .btn {
        font-size: 0.9375rem;
        border-radius: 1.875rem;
        height: 2.5rem;
        line-height: 2.25rem;
        padding: 0 1.5rem; } }
    body .btn:hover {
      background: #fff;
      color: #646464; }

.compat-object-fit {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  .compat-object-fit img {
    opacity: 0; }

.top-menu {
  height: 1.875rem;
  background: #efefef;
  position: relative;
  z-index: 2; }
  .top-menu .top-menu-right > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    line-height: 1.875rem; }
    .top-menu .top-menu-right > ul > li {
      display: inline-block;
      line-height: 1.875rem; }
  .top-menu .top-menu-right {
    float: right;
    margin-right: 0;
    height: 1.875rem; }
    .top-menu .top-menu-right .dropdown {
      font-size: 0; }
      .top-menu .top-menu-right .dropdown > li {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        margin: 0;
        padding: 0; }
        .top-menu .top-menu-right .dropdown > li:last-child > a {
          margin-right: 0; }
        .top-menu .top-menu-right .dropdown > li > a {
          color: #1e1e1e;
          display: block;
          height: 1.875rem;
          line-height: 1.375rem;
          margin: 0 1.0625rem;
          padding: 0 0.4375rem 0 0;
          font-size: 0.875rem;
          letter-spacing: 0.01875rem; }
          .top-menu .top-menu-right .dropdown > li > a:hover {
            color: #4e4e4e; }
        .top-menu .top-menu-right .dropdown > li .submenu {
          z-index: 9999 !important; }
          .top-menu .top-menu-right .dropdown > li .submenu li a {
            color: #333;
            font-size: 0.875rem; }
          .top-menu .top-menu-right .dropdown > li .submenu li.divider {
            border-bottom: 0.0625rem solid #eee;
            display: block; }
        .top-menu .top-menu-right .dropdown > li.is-dropdown-submenu-parent > a {
          position: relative; }
          .top-menu .top-menu-right .dropdown > li.is-dropdown-submenu-parent > a:after {
            content: "\e906";
            font-family: 'icomoon';
            border: none;
            position: absolute;
            top: 0.25rem;
            right: 0;
            font-size: 0.3125rem; }
        .top-menu .top-menu-right .dropdown > li.is-dropdown-submenu-parent:hover > a:after {
          content: "\e905"; }

.clear {
  clear: both; }

.header {
  padding: 0.625rem 0;
  position: relative;
  z-index: 1;
  background: #fff;
  border-top: 0.5rem solid #BFDB08;
  border-bottom: 0.0625rem solid #D2D2D2; }
  @media print, screen and (min-width: 64em) {
    .header {
      border-top: none; } }
  .header .logo {
    padding: 1.25rem 0 1.25rem 1.875rem;
    float: left; }
    @media print, screen and (min-width: 64em) {
      .header .logo {
        padding: 0; } }
    .header .logo a img {
      width: 9.375rem; }
      @media print, screen and (min-width: 64em) {
        .header .logo a img {
          width: 9.375rem; } }
  .header .mobile-menu-btn {
    position: absolute;
    top: 3.125rem;
    right: 3.125rem; }
    .header .mobile-menu-btn .menu-button {
      display: inline-block; }
      .header .mobile-menu-btn .menu-button .hamburger {
        width: 3.125rem;
        height: 3.125rem;
        position: relative; }
        .header .mobile-menu-btn .menu-button .hamburger:hover span {
          border-color: #BFDB08; }
        .header .mobile-menu-btn .menu-button .hamburger span {
          border-top: 0.1875rem solid #3b3b41;
          display: block;
          opacity: 1;
          position: absolute;
          width: 100%;
          top: 0;
          -webkit-transition: all .6s ease;
          -moz-transition: all .6s ease;
          -o-transition: all .6s ease;
          transition: all .6s ease; }
          .header .mobile-menu-btn .menu-button .hamburger span:first-of-type {
            top: 0; }
          .header .mobile-menu-btn .menu-button .hamburger span:nth-of-type(2) {
            top: 0.875rem; }
          .header .mobile-menu-btn .menu-button .hamburger span:last-of-type {
            top: 1.75rem; }
          .header .mobile-menu-btn .menu-button .hamburger span:after {
            display: none; }
  .header .the-mobile-menu {
    clear: both;
    background: #F5F5F5;
    display: none;
    position: absolute;
    top: 7.25rem;
    left: 0;
    right: 0;
    height: 100vh;
    overflow-y: auto; }
    @media print, screen and (min-width: 64em) {
      .header .the-mobile-menu {
        clear: none;
        background: none;
        display: block;
        position: static;
        height: auto;
        overflow-y: visible;
        float: right; } }
    .header .the-mobile-menu > ul {
      list-style: none;
      margin: 0;
      padding: 2.5rem 3.125rem;
      line-height: 4.6875rem; }
      @media print, screen and (min-width: 64em) {
        .header .the-mobile-menu > ul {
          padding: 0; } }
      @media print, screen and (min-width: 64em) {
        .header .the-mobile-menu > ul > li {
          display: inline-block;
          padding: 0 1.5625rem;
          line-height: 1;
          border-right: 0.0625rem solid #D2D2D2;
          position: relative;
          z-index: 1; } }
      @media print, screen and (min-width: 64em) {
        .header .the-mobile-menu > ul > li:last-child {
          padding-right: 0;
          border-right: none; } }
      .header .the-mobile-menu > ul > li.is-dropdown-submenu-parent {
        position: relative; }
        .header .the-mobile-menu > ul > li.is-dropdown-submenu-parent > a:after {
          content: "\e909";
          font-family: 'icomoon';
          font-size: 1.625rem;
          position: absolute;
          right: 0;
          top: 0.75rem;
          transition: all 0.1s linear; }
          @media print, screen and (min-width: 64em) {
            .header .the-mobile-menu > ul > li.is-dropdown-submenu-parent > a:after {
              display: none; } }
      .header .the-mobile-menu > ul > li > a {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 1.9375rem;
        letter-spacing: 0.0125rem;
        color: #3B3B41;
        padding-bottom: 0.1875rem; }
        @media print, screen and (min-width: 64em) {
          .header .the-mobile-menu > ul > li > a:hover {
            border-bottom: 0.125rem solid #008DA8; } }
        @media print, screen and (min-width: 64em) {
          .header .the-mobile-menu > ul > li > a {
            font-size: 1.0625rem; } }
      @media print, screen and (min-width: 64em) {
        .header .the-mobile-menu > ul > li.active a, .header .the-mobile-menu > ul > li.is-active a {
          border-bottom: 0.125rem solid #008DA8; } }
      .header .the-mobile-menu > ul > li.is-active a:after {
        transform: rotate(90deg); }
      .header .the-mobile-menu > ul > li .submenu {
        position: static;
        border: none;
        list-style: none;
        background: none;
        margin: 0; }
        @media print, screen and (min-width: 64em) {
          .header .the-mobile-menu > ul > li .submenu {
            position: absolute;
            min-width: 16.875rem;
            padding: 1.875rem 1.875rem 0.9375rem 1.875rem;
            z-index: 999 !important;
            background: rgba(165, 165, 165, 0.95);
            margin: 0;
            top: 3.5rem;
            left: 0; } }
        .header .the-mobile-menu > ul > li .submenu li {
          line-height: 1;
          padding: 0; }
          .header .the-mobile-menu > ul > li .submenu li a {
            display: block;
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            color: #3B3B41;
            padding: 0;
            font-size: 1.625rem;
            line-height: 3.75rem;
            border-bottom: 0.0625rem solid transparent;
            letter-spacing: 0.0125rem;
            white-space: nowrap;
            margin-bottom: 0.9375rem; }
            @media print, screen and (min-width: 64em) {
              .header .the-mobile-menu > ul > li .submenu li a {
                display: inline-block;
                color: #fff;
                padding: 0;
                font-size: 1rem;
                line-height: 1.3125rem;
                border-color: transparent; } }
            @media print, screen and (min-width: 64em) {
              .header .the-mobile-menu > ul > li .submenu li a:hover {
                border-bottom: 0.0625rem solid #FFFFFF; } }
          @media print, screen and (min-width: 64em) {
            .header .the-mobile-menu > ul > li .submenu li.active a {
              border-bottom: 0.0625rem solid #FFFFFF; } }

.footer {
  padding: 3.125rem 1.25rem 4.375rem 1.25rem;
  background: #f5f5f5; }
  @media print, screen and (min-width: 64em) {
    .footer {
      padding: 3.125rem 0 4.375rem 0; } }
  .footer .footer-logo {
    padding-top: 3.125rem; }
    @media print, screen and (min-width: 64em) {
      .footer .footer-logo {
        padding-top: 0;
        text-align: right; } }
    .footer .footer-logo img {
      width: 15.625rem; }
      @media print, screen and (min-width: 64em) {
        .footer .footer-logo img {
          width: 10rem; } }
  .footer .footer-links {
    border-top: 0.125rem solid #fff;
    padding-top: 3.125rem;
    margin-top: 3.125rem; }
    @media print, screen and (min-width: 64em) {
      .footer .footer-links {
        margin-top: 2.1875rem;
        padding-top: 0.9375rem;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row; } }
    .footer .footer-links ul {
      list-style: none;
      margin: 0;
      padding: 0;
      column-count: 2;
      display: inline;
      flex-grow: 2; }
      @media print, screen and (min-width: 64em) {
        .footer .footer-links ul {
          column-count: 1; } }
      .footer .footer-links ul li {
        display: block; }
        @media print, screen and (min-width: 64em) {
          .footer .footer-links ul li {
            display: inline-block; } }
        .footer .footer-links ul li a {
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          font-size: 1.5625rem;
          line-height: 4.0625rem;
          color: #1E1E1E; }
          @media print, screen and (min-width: 64em) {
            .footer .footer-links ul li a {
              margin-right: 1.25rem;
              font-size: 0.875rem;
              line-height: 1.5rem; } }
          .footer .footer-links ul li a:hover {
            color: #636363; }
    .footer .footer-links span {
      padding-top: 3.125rem;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 1.375rem;
      line-height: 2.375rem;
      color: #1E1E1E;
      display: block; }
      @media print, screen and (min-width: 64em) {
        .footer .footer-links span {
          padding-top: 0;
          display: inline;
          font-size: 0.875rem;
          line-height: 1.5rem;
          align-self: flex-end; } }

#google_translate_element .goog-te-gadget-simple {
  border: none;
  padding: 0;
  margin: 0;
  display: block;
  position: relative;
  background: transparent; }
  #google_translate_element .goog-te-gadget-simple a.goog-te-menu-value {
    display: block;
    position: relative;
    color: #1e1e1e !important;
    height: 1.875rem;
    line-height: 1.375rem;
    margin: 0 0 0 1.375rem;
    padding: 0 0.4375rem 0 0;
    font-size: 0.875rem;
    letter-spacing: 0.01875rem; }
    #google_translate_element .goog-te-gadget-simple a.goog-te-menu-value > span {
      display: none; }
      #google_translate_element .goog-te-gadget-simple a.goog-te-menu-value > span:first-of-type {
        display: inline-block; }
    #google_translate_element .goog-te-gadget-simple a.goog-te-menu-value:after {
      content: "\e906";
      font-family: 'icomoon';
      border: none;
      position: absolute;
      top: 0.0625rem;
      right: 0;
      font-size: 0.3125rem; }
    #google_translate_element .goog-te-gadget-simple a.goog-te-menu-value:hover {
      color: #4e4e4e !important; }
      #google_translate_element .goog-te-gadget-simple a.goog-te-menu-value:hover:after {
        content: "\e905"; }

#google_translate_element .goog-te-gadget-icon {
  display: none; }

.intro {
  position: relative;
  z-index: 1; }
  .intro .image {
    background-position: center right;
    background-size: cover;
    background-repeat: no-repeat;
    height: 32.5rem;
    position: relative;
    width: 100vw; }
    @media print, screen and (min-width: 64em) {
      .intro .image {
        height: 47.5rem; } }
  @media print, screen and (min-width: 64em) {
    .intro .main-ctas {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      z-index: 5;
      transform: translateY(-50%);
      display: flex;
      width: 100%; } }
  .intro .main-ctas .patient {
    background: rgba(15, 98, 115, 0.9); }
  .intro .main-ctas .provider {
    background: rgba(118, 118, 118, 0.9); }
  .intro .main-ctas .patient,
  .intro .main-ctas .provider {
    padding: 4.0625rem;
    text-align: center; }
    @media print, screen and (min-width: 64em) {
      .intro .main-ctas .patient,
      .intro .main-ctas .provider {
        padding: 2.8125rem;
        width: 50%; } }
    .intro .main-ctas .patient p,
    .intro .main-ctas .provider p {
      margin: 2.1875rem auto 0 auto;
      font-size: 1.75rem;
      line-height: 2.5rem;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      color: #fff; }
      @media print, screen and (min-width: 64em) {
        .intro .main-ctas .patient p,
        .intro .main-ctas .provider p {
          width: 23.125rem;
          font-size: 1.3125rem;
          line-height: 1.875rem;
          letter-spacing: 0.00625rem;
          margin: 1.5625rem auto 0 auto; } }
    .intro .main-ctas .patient .btn,
    .intro .main-ctas .provider .btn {
      margin: 0;
      display: block;
      height: 5rem;
      line-height: 4.625rem;
      background: #fff;
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 1.75rem;
      color: #0F6273;
      width: 100%;
      border: 0.125rem solid #fff; }
      @media print, screen and (min-width: 64em) {
        .intro .main-ctas .patient .btn,
        .intro .main-ctas .provider .btn {
          width: 26.875rem;
          display: inline-block;
          margin: 0 auto;
          height: 3.75rem;
          line-height: 3.375rem;
          font-size: 1.375rem; } }
      .intro .main-ctas .patient .btn:hover,
      .intro .main-ctas .provider .btn:hover {
        background: #fff;
        color: #767676; }

.breadcrumbs-wrapper {
  background: #fff;
  padding: 2.5rem 1.25rem;
  display: none; }
  @media print, screen and (min-width: 64em) {
    .breadcrumbs-wrapper {
      display: block;
      padding: 1.875rem 0; } }
  .breadcrumbs-wrapper .breadcrumbs {
    display: block;
    margin: 0; }
    .breadcrumbs-wrapper .breadcrumbs ul {
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: 0; }
      .breadcrumbs-wrapper .breadcrumbs ul li {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 0.9375rem;
        line-height: 1.0625rem;
        color: #3B3B41;
        letter-spacing: 0.025rem; }
        .breadcrumbs-wrapper .breadcrumbs ul li a {
          color: #767676; }

.image-banner {
  height: 22.5rem; }
  .image-banner .big-row {
    position: relative; }
  .image-banner img {
    width: 100%;
    height: 22.5rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    object-fit: cover;
    object-position: right center; }
  .image-banner .title {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    height: 22.5rem; }
    .image-banner .title h1 {
      background: rgba(118, 118, 118, 0.95);
      position: absolute;
      left: -3.75rem;
      padding: 0 2.8125rem 0 6.5625rem;
      text-align: right;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 2.125rem;
      color: #fff;
      height: 5rem;
      line-height: 5rem; }

.title-banner {
  background: #767676; }
  .title-banner .title h1 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 2.125rem;
    color: #fff;
    margin: 0;
    padding: 1.25rem 0; }

@media print, screen and (min-width: 64em) {
  .page-container {
    padding: 0 1.25rem; } }

.page-container > .row {
  display: block; }
  @media print, screen and (min-width: 64em) {
    .page-container > .row {
      display: flex; } }

.page-container .page-content {
  padding: 1.25rem; }
  @media print, screen and (min-width: 64em) {
    .page-container .page-content {
      padding: 2.5rem 0; } }
  @media print, screen and (min-width: 64em) {
    .page-container .page-content.right-space {
      margin-right: 6.25rem; } }
  .page-container .page-content .the-content img {
    width: 100%;
    margin: 0 0 1.875rem 0; }
  .page-container .page-content .the-content ul, .page-container .page-content .the-content ol {
    list-style: none;
    margin: 0 0 1.4375rem 0; }
    .page-container .page-content .the-content ul li, .page-container .page-content .the-content ol li {
      font-size: 1.875rem;
      line-height: 2.875rem;
      padding: 0.4375rem 1.25rem;
      font-family: 'Roboto', sans-serif;
      position: relative; }
      @media print, screen and (min-width: 64em) {
        .page-container .page-content .the-content ul li, .page-container .page-content .the-content ol li {
          font-size: 1.1875rem;
          line-height: 1.875rem; } }
  .page-container .page-content .the-content ul > li:before {
    content: '•';
    left: 0;
    color: #767676;
    position: absolute; }
  .page-container .page-content .the-content h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.875rem;
    letter-spacing: 0.00625rem;
    margin: 2.5rem 0 1.25rem 0;
    color: #1E1E1E; }
    @media print, screen and (min-width: 64em) {
      .page-container .page-content .the-content h2 {
        font-size: 1.75rem;
        line-height: 1.8125rem; } }
  .page-container .page-content .the-content h3 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.875rem;
    letter-spacing: 0.00625rem;
    border-bottom: 0.0625rem solid #008DA8;
    padding-bottom: 0.625rem;
    margin-bottom: 1.25rem;
    color: #1E1E1E; }
    @media print, screen and (min-width: 64em) {
      .page-container .page-content .the-content h3 {
        font-size: 1.375rem;
        line-height: 1.875rem; } }
    .page-container .page-content .the-content h3.nounderline {
      border-bottom: 0;
      padding-bottom: 0; }
  .page-container .page-content .the-content p {
    font-size: 1.875rem;
    line-height: 2.875rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #1E1E1E;
    letter-spacing: 0.01875rem; }
    @media print, screen and (min-width: 64em) {
      .page-container .page-content .the-content p {
        font-size: 1.1875rem;
        line-height: 1.875rem; } }
    .page-container .page-content .the-content p strong {
      font-family: 'Roboto', sans-serif;
      font-weight: 700; }
    .page-container .page-content .the-content p a {
      color: #0F6273;
      text-decoration: underline; }
  .page-container .page-content .the-content a {
    color: #0F6273;
    text-decoration: underline;
    font-size: 1.875rem;
    line-height: 2.875rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    letter-spacing: 0.01875rem; }
    @media print, screen and (min-width: 64em) {
      .page-container .page-content .the-content a {
        font-size: 1.1875rem;
        line-height: 1.875rem; } }

.page-container .sidebar {
  padding-top: 2.5rem;
  margin: 0 -1.25rem; }
  @media print, screen and (min-width: 64em) {
    .page-container .sidebar {
      margin: 0 0 3.125rem 0; } }
  .page-container .sidebar .widget {
    padding: 1.875rem 3.75rem;
    position: relative;
    z-index: 2;
    background: #EFEFEF; }
    @media print, screen and (min-width: 64em) {
      .page-container .sidebar .widget {
        padding: 1.875rem 1.5625rem 1.875rem 1.5625rem; } }
    .page-container .sidebar .widget.with-border {
      padding-bottom: 0; }
      .page-container .sidebar .widget.with-border:after {
        content: '';
        height: 0.125rem;
        width: 100%;
        background: #fff;
        display: block;
        margin-top: 1.875rem; }
    .page-container .sidebar .widget .widget-title h2 {
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 2.375rem;
      line-height: 1.5625rem;
      padding-bottom: 1.5625rem;
      border-bottom: 0.125rem solid #008DAA;
      margin: 0; }
      @media print, screen and (min-width: 64em) {
        .page-container .sidebar .widget .widget-title h2 {
          font-size: 1.25rem;
          padding-bottom: 0.9375rem; } }
    .page-container .sidebar .widget .widget-content ul {
      list-style: none;
      margin: 0;
      padding: 0; }
      .page-container .sidebar .widget .widget-content ul li {
        padding: 0.9375rem 0; }
        .page-container .sidebar .widget .widget-content ul li a {
          font-family: 'Roboto', sans-serif;
          font-weight: 500;
          font-size: 1.9375rem;
          line-height: 2.5rem;
          padding-left: 1.875rem;
          color: #000;
          position: relative;
          display: block; }
          @media print, screen and (min-width: 64em) {
            .page-container .sidebar .widget .widget-content ul li a {
              padding-left: 1.25rem;
              font-size: 1.125rem;
              line-height: 1.4375rem; } }
          .page-container .sidebar .widget .widget-content ul li a:before {
            font-family: 'icomoon';
            content: "\e909";
            margin-top: 0;
            position: absolute;
            left: 0;
            transition: all 0.3s;
            font-size: 1.375rem;
            color: #008DA8;
            display: none; }
            @media print, screen and (min-width: 64em) {
              .page-container .sidebar .widget .widget-content ul li a:before {
                font-size: 0.75rem; } }
          .page-container .sidebar .widget .widget-content ul li a:hover {
            color: #0F6273; }
            .page-container .sidebar .widget .widget-content ul li a:hover:before {
              display: block; }
        .page-container .sidebar .widget .widget-content ul li.active a:before {
          display: block; }
    .page-container .sidebar .widget .widget-content h3 {
      font-size: 2.1875rem;
      line-height: 2.25rem;
      color: #0F6273;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      margin-bottom: 1.25rem; }
      @media print, screen and (min-width: 64em) {
        .page-container .sidebar .widget .widget-content h3 {
          font-size: 1.5625rem;
          line-height: 1.625rem; } }
    .page-container .sidebar .widget .widget-content p {
      font-size: 1.625rem;
      line-height: 2.125rem;
      color: #1E1E1E;
      font-family: 'Roboto', sans-serif;
      font-weight: 400; }
      @media print, screen and (min-width: 64em) {
        .page-container .sidebar .widget .widget-content p {
          font-size: 1rem;
          line-height: 1.5rem; } }
      .page-container .sidebar .widget .widget-content p a {
        text-decoration: underline; }
    .page-container .sidebar .widget .widget-content .phone {
      display: table; }
      .page-container .sidebar .widget .widget-content .phone span {
        display: table-cell;
        font-size: 1.625rem;
        line-height: 2.125rem;
        color: #1E1E1E;
        font-family: 'Roboto', sans-serif;
        font-weight: 500; }
        .page-container .sidebar .widget .widget-content .phone span:first-child {
          padding-right: 0.75rem; }
        @media print, screen and (min-width: 64em) {
          .page-container .sidebar .widget .widget-content .phone span {
            font-size: 1rem;
            line-height: 1.5rem; } }

.page-accordion {
  margin-bottom: 2.5rem; }
  .page-accordion .expand-all {
    text-align: right;
    margin-bottom: 0.625rem; }
    .page-accordion .expand-all button {
      font-family: 'Roboto', sans-serif;
      font-weight: 900;
      color: #646464;
      font-size: 1.625rem;
      line-height: 2.375rem;
      letter-spacing: 0.03125rem;
      text-transform: uppercase;
      cursor: pointer; }
      @media print, screen and (min-width: 64em) {
        .page-accordion .expand-all button {
          font-size: 0.875rem;
          line-height: 1.625rem; } }
  .page-accordion .accordion {
    list-style: none; }
    .page-accordion .accordion .accordion-item .accordion-title {
      border-left: none;
      border-right: none;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      color: #000;
      font-size: 2.0625rem;
      line-height: 2.625rem;
      letter-spacing: 0.00625rem;
      border-color: #008DA8;
      padding-left: 2.1875rem; }
      @media print, screen and (min-width: 64em) {
        .page-accordion .accordion .accordion-item .accordion-title {
          font-size: 1.375rem;
          line-height: 1.875rem; } }
      .page-accordion .accordion .accordion-item .accordion-title[aria-expanded="true"] {
        border-bottom: none;
        background: none; }
        .page-accordion .accordion .accordion-item .accordion-title[aria-expanded="true"]:before {
          transform: rotate(90deg); }
      .page-accordion .accordion .accordion-item .accordion-title:hover, .page-accordion .accordion .accordion-item .accordion-title:focus {
        background: none; }
      .page-accordion .accordion .accordion-item .accordion-title:before {
        font-family: 'icomoon';
        content: "\e909";
        margin-top: 0;
        top: 1.25rem;
        right: auto;
        left: 0;
        transition: all 0.3s;
        font-size: 1rem;
        color: #008DA8; }
    .page-accordion .accordion .accordion-item .accordion-content {
      border: none;
      padding: 1.25rem 0; }
      .page-accordion .accordion .accordion-item .accordion-content img {
        width: 100%;
        margin: 0 0 1.875rem 0; }
      .page-accordion .accordion .accordion-item .accordion-content ul, .page-accordion .accordion .accordion-item .accordion-content ol {
        list-style: none;
        margin: 0 0 1.4375rem 0; }
        .page-accordion .accordion .accordion-item .accordion-content ul li, .page-accordion .accordion .accordion-item .accordion-content ol li {
          font-size: 1.875rem;
          line-height: 2.875rem;
          padding: 0.4375rem 1.25rem;
          font-family: 'Roboto', sans-serif;
          position: relative; }
          @media print, screen and (min-width: 64em) {
            .page-accordion .accordion .accordion-item .accordion-content ul li, .page-accordion .accordion .accordion-item .accordion-content ol li {
              font-size: 1.1875rem;
              line-height: 1.875rem; } }
      .page-accordion .accordion .accordion-item .accordion-content ul > li:before {
        content: '•';
        left: 0;
        color: #767676;
        position: absolute; }
      .page-accordion .accordion .accordion-item .accordion-content h2 {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 2rem;
        line-height: 2.875rem;
        letter-spacing: 0.00625rem;
        margin: 1.25rem 0;
        color: #1E1E1E; }
        @media print, screen and (min-width: 64em) {
          .page-accordion .accordion .accordion-item .accordion-content h2 {
            font-size: 1.75rem;
            line-height: 1.8125rem; } }
      .page-accordion .accordion .accordion-item .accordion-content h3 {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 2rem;
        line-height: 2.875rem;
        letter-spacing: 0.00625rem;
        border-bottom: 0.0625rem solid #008DA8;
        padding-bottom: 0.625rem;
        margin-bottom: 1.25rem;
        color: #1E1E1E; }
        @media print, screen and (min-width: 64em) {
          .page-accordion .accordion .accordion-item .accordion-content h3 {
            font-size: 1.375rem;
            line-height: 1.875rem; } }
        .page-accordion .accordion .accordion-item .accordion-content h3.nounderline {
          border-bottom: 0;
          padding-bottom: 0; }
      .page-accordion .accordion .accordion-item .accordion-content p {
        font-size: 1.875rem;
        line-height: 2.875rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        color: #1E1E1E;
        letter-spacing: 0.01875rem; }
        @media print, screen and (min-width: 64em) {
          .page-accordion .accordion .accordion-item .accordion-content p {
            font-size: 1.1875rem;
            line-height: 1.875rem; } }
        .page-accordion .accordion .accordion-item .accordion-content p strong {
          font-family: 'Roboto', sans-serif;
          font-weight: 700; }
      .page-accordion .accordion .accordion-item .accordion-content a {
        color: #0F6273;
        text-decoration: underline;
        font-size: 1.875rem;
        line-height: 2.875rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        letter-spacing: 0.01875rem; }
        @media print, screen and (min-width: 64em) {
          .page-accordion .accordion .accordion-item .accordion-content a {
            font-size: 1.1875rem;
            line-height: 1.875rem; } }

body.high-contrast img {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%); }

body.high-contrast .btn {
  background: #000 !important;
  border-color: #000 !important; }
  body.high-contrast .btn:hover {
    background: #fff !important;
    color: #000 !important; }

body.high-contrast .intro .image {
  filter: grayscale(100%); }

body.high-contrast .top-menu {
  background: #000; }
  body.high-contrast .top-menu .top-menu-left > ul > li > a,
  body.high-contrast .top-menu .top-menu-right > ul > li > a {
    color: #fff; }
    body.high-contrast .top-menu .top-menu-left > ul > li > a:hover,
    body.high-contrast .top-menu .top-menu-right > ul > li > a:hover {
      color: #fff; }
  body.high-contrast .top-menu .top-menu-left > ul > li .goog-te-menu-value span,
  body.high-contrast .top-menu .top-menu-right > ul > li .goog-te-menu-value span {
    color: #fff; }
  body.high-contrast .top-menu .top-menu-left > ul > li .goog-te-menu-value:after,
  body.high-contrast .top-menu .top-menu-right > ul > li .goog-te-menu-value:after {
    color: #fff; }

body.high-contrast .footer {
  background: #000; }
  body.high-contrast .footer .footer-links ul li a {
    color: #fff; }
    body.high-contrast .footer .footer-links ul li a:hover {
      color: #fff; }
  body.high-contrast .footer .footer-links span {
    color: #fff; }

body.low-contrast {
  filter: grayscale(100%) contrast(50%); }
  body.low-contrast.surgery-expanded .surgery-dropmenu .surgery-menu-btn {
    background: #000;
    color: #fff; }
  body.low-contrast img {
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%); }
  body.low-contrast #mobile-header .mobile-header {
    border-top: 0.5rem solid #000; }
  body.low-contrast #mobile-header .mobile-menu .mobile-menu-header {
    background: #000;
    border-top: 0.5rem solid #000; }
    body.low-contrast #mobile-header .mobile-menu .mobile-menu-header ul li a {
      color: #fff; }
    body.low-contrast #mobile-header .mobile-menu .mobile-menu-header ul li.pink:hover a, body.low-contrast #mobile-header .mobile-menu .mobile-menu-header ul li.pink.active a {
      border-color: #fff; }
    body.low-contrast #mobile-header .mobile-menu .mobile-menu-header ul li.blue:hover a, body.low-contrast #mobile-header .mobile-menu .mobile-menu-header ul li.blue.active a {
      border-color: #fff; }
    body.low-contrast #mobile-header .mobile-menu .mobile-menu-header ul li.grey:hover a, body.low-contrast #mobile-header .mobile-menu .mobile-menu-header ul li.grey.active a {
      border-color: #fff; }
    body.low-contrast #mobile-header .mobile-menu .mobile-menu-header ul li.green:hover a, body.low-contrast #mobile-header .mobile-menu .mobile-menu-header ul li.green.active a {
      border-color: #fff; }
  body.low-contrast #mobile-header .mobile-menu .mobile-menu-search .search-form form input {
    border: 0.125rem solid #000;
    color: #000; }
    body.low-contrast #mobile-header .mobile-menu .mobile-menu-search .search-form form input::placeholder {
      color: #000; }
    body.low-contrast #mobile-header .mobile-menu .mobile-menu-search .search-form form input:focus {
      border-color: #000; }
  body.low-contrast #mobile-header .mobile-menu .mobile-menu-search .search-form form button i {
    color: #000; }
  body.low-contrast #mobile-header .mobile-menu .mobile-menu-list .accordion .accordion-item .accordion-title {
    color: #000; }
    body.low-contrast #mobile-header .mobile-menu .mobile-menu-list .accordion .accordion-item .accordion-title[aria-expanded="true"] {
      border-bottom: 0.375rem solid #333; }
      body.low-contrast #mobile-header .mobile-menu .mobile-menu-list .accordion .accordion-item .accordion-title[aria-expanded="true"]:before {
        content: "\ea0a"; }
    body.low-contrast #mobile-header .mobile-menu .mobile-menu-list .accordion .accordion-item .accordion-title:hover {
      background: #000;
      color: #fff; }
  body.low-contrast #mobile-header .mobile-menu .mobile-menu-list .accordion .accordion-item .accordion-content ul li a {
    color: #000; }
  body.low-contrast #mobile-header .mobile-menu .mobile-menu-footer {
    background: #000; }
    body.low-contrast #mobile-header .mobile-menu .mobile-menu-footer ul.search-list li a:hover {
      color: #fff; }
    body.low-contrast #mobile-header .mobile-menu .mobile-menu-footer ul.social-links li a:hover {
      color: #fff; }
    body.low-contrast #mobile-header .mobile-menu .mobile-menu-footer ul.dropdown > li > a {
      color: #fff; }
      body.low-contrast #mobile-header .mobile-menu .mobile-menu-footer ul.dropdown > li > a:hover {
        color: #fff; }
    body.low-contrast #mobile-header .mobile-menu .mobile-menu-footer ul.dropdown > li .submenu li a {
      color: #000; }
  body.low-contrast .top-menu {
    background: #000; }
    body.low-contrast .top-menu .top-menu-left .tabs-menu li.pink, body.low-contrast .top-menu .top-menu-left .tabs-menu li.green, body.low-contrast .top-menu .top-menu-left .tabs-menu li.blue, body.low-contrast .top-menu .top-menu-left .tabs-menu li.grey,
    body.low-contrast .top-menu .top-menu-right .tabs-menu li.pink,
    body.low-contrast .top-menu .top-menu-right .tabs-menu li.green,
    body.low-contrast .top-menu .top-menu-right .tabs-menu li.blue,
    body.low-contrast .top-menu .top-menu-right .tabs-menu li.grey {
      border-color: #eee; }
    body.low-contrast .top-menu .top-menu-left .tabs-menu li.active a, body.low-contrast .top-menu .top-menu-left .tabs-menu li:hover a,
    body.low-contrast .top-menu .top-menu-right .tabs-menu li.active a,
    body.low-contrast .top-menu .top-menu-right .tabs-menu li:hover a {
      background: #333; }
    body.low-contrast .top-menu .top-menu-left > ul > li > a,
    body.low-contrast .top-menu .top-menu-right > ul > li > a {
      color: #fff; }
      body.low-contrast .top-menu .top-menu-left > ul > li > a:hover,
      body.low-contrast .top-menu .top-menu-right > ul > li > a:hover {
        color: #fff; }
    body.low-contrast .top-menu .top-menu-left > ul > li .goog-te-menu-value span,
    body.low-contrast .top-menu .top-menu-right > ul > li .goog-te-menu-value span {
      color: #fff; }
    body.low-contrast .top-menu .top-menu-left > ul > li .goog-te-menu-value:after,
    body.low-contrast .top-menu .top-menu-right > ul > li .goog-te-menu-value:after {
      color: #fff; }
  body.low-contrast .header .search-container .search-list li.donate-button a {
    background: #000; }
  body.low-contrast .header .search-container .search-list li.search-form input {
    border-color: #000; }
  body.low-contrast .header .search-container .search-list li.search-form button i {
    color: #000; }
  body.low-contrast .header .search-container .social-links li a {
    color: #000; }
  body.low-contrast .mega-menu-bar {
    background: #000; }
    body.low-contrast .mega-menu-bar .mega-menu > ul > li > a {
      color: #fff; }
      body.low-contrast .mega-menu-bar .mega-menu > ul > li > a:after {
        color: #fff; }
    body.low-contrast .mega-menu-bar .mega-menu > ul > li.has-sub-menu .sub-menu h2 {
      color: #000;
      border-color: #000; }
    body.low-contrast .mega-menu-bar .mega-menu > ul > li.has-sub-menu .mega-sub-menu > ul > li > a {
      color: #000; }
  body.low-contrast .slider .slide .slide-details {
    background: black; }
    body.low-contrast .slider .slide .slide-details:after {
      background: rgba(0, 0, 0, 0.8); }
    body.low-contrast .slider .slide .slide-details .slide-details-inner .details .cta-button:hover {
      color: #000; }
  body.low-contrast .static-slide .slide-details {
    background: black; }
    body.low-contrast .static-slide .slide-details:after {
      background: rgba(0, 0, 0, 0.8); }
    body.low-contrast .static-slide .slide-details .slide-details-inner .details .cta-button:hover {
      color: #000; }
  body.low-contrast .main-sections .section-box a {
    color: #000; }
    body.low-contrast .main-sections .section-box a span {
      color: #000; }
    body.low-contrast .main-sections .section-box a .section {
      background: #000; }
    body.low-contrast .main-sections .section-box a:hover .section {
      background: #000; }
  body.low-contrast .care-slider .slider-title h3 {
    color: #000;
    border-color: #000; }
  body.low-contrast .care-slider .slides .slide .slide-inner .slide-inner-text:after {
    background: rgba(0, 0, 0, 0.8); }
  body.low-contrast .care-slider .slick-prev,
  body.low-contrast .care-slider .slick-next {
    background: #000000; }
    body.low-contrast .care-slider .slick-prev:before,
    body.low-contrast .care-slider .slick-next:before {
      color: #fff;
      opacity: 1; }
  body.low-contrast .care-slider-mobile .slider-title h3 {
    color: #000;
    border-color: #000; }
  body.low-contrast .care-slider-mobile .mobile-slides .slide a .slide-inner .slide-inner-text {
    background: #000; }
    body.low-contrast .care-slider-mobile .mobile-slides .slide a .slide-inner .slide-inner-text:after {
      background: rgba(0, 0, 0, 0.5); }
  body.low-contrast .care-slider-mobile .mobile-slides .slide a .slide-inner.slide-green .slide-inner-text:after {
    background: rgba(0, 0, 0, 0.8); }
  body.low-contrast .care-slider-mobile .mobile-slides .slide a .slide-inner.slide-pink .slide-inner-text:after {
    background: rgba(0, 0, 0, 0.8); }
  body.low-contrast .care-slider-mobile .mobile-slides .slide a .slide-inner.slide-navy .slide-inner-text:after {
    background: rgba(0, 0, 0, 0.8); }
  body.low-contrast .care-slider-mobile .slick-prev,
  body.low-contrast .care-slider-mobile .slick-next {
    border: 0.25rem solid #000;
    background: #fff;
    color: #000; }
    body.low-contrast .care-slider-mobile .slick-prev.slick-disabled, body.low-contrast .care-slider-mobile .slick-prev:hover,
    body.low-contrast .care-slider-mobile .slick-next.slick-disabled,
    body.low-contrast .care-slider-mobile .slick-next:hover {
      background: #fff;
      color: #000; }
    body.low-contrast .care-slider-mobile .slick-prev:before,
    body.low-contrast .care-slider-mobile .slick-next:before {
      color: #000; }
  body.low-contrast .last-news .block-title h3 {
    color: #000;
    border-color: #000; }
  body.low-contrast .last-news .news .single-post a:hover h3,
  body.low-contrast .last-news .news .single-post a:hover p {
    color: #000; }
  body.low-contrast .last-news .news .single-post a .category h5:after {
    background: rgba(0, 0, 0, 0.8); }
  body.low-contrast .newsletter-block {
    background: #000000; }
    body.low-contrast .newsletter-block .newsletter-link h3 a {
      color: #fff; }
      body.low-contrast .newsletter-block .newsletter-link h3 a i {
        color: #000;
        background: #fff;
        border-color: #000; }
    body.low-contrast .newsletter-block .newsletter-form p,
    body.low-contrast .newsletter-block .newsletter-form label,
    body.low-contrast .newsletter-block #campignerForm p,
    body.low-contrast .newsletter-block #campignerForm label {
      color: #fff; }
  body.low-contrast .social-block .block-title h3 {
    color: #000;
    border-color: #000; }
  body.low-contrast .social-block .tweets .tweet .tweet-icon i {
    background: #000; }
  body.low-contrast .social-block .slick-dots li.slick-active button:before {
    background: #000;
    border-color: #000; }
  body.low-contrast .footer {
    background: #000; }
    body.low-contrast .footer .contact-details {
      border-bottom: 0.0625rem solid #000; }
      body.low-contrast .footer .contact-details .small-inline .email a {
        color: #fff; }
        body.low-contrast .footer .contact-details .small-inline .email a:hover {
          color: #fff; }
      body.low-contrast .footer .contact-details .small-inline .phone-number a {
        color: #fff; }
        body.low-contrast .footer .contact-details .small-inline .phone-number a:hover {
          color: #fff; }
      body.low-contrast .footer .contact-details .small-inline .directions a {
        color: #fff; }
        body.low-contrast .footer .contact-details .small-inline .directions a i {
          color: #fff; }
        body.low-contrast .footer .contact-details .small-inline .directions a:hover {
          color: #fff; }
          body.low-contrast .footer .contact-details .small-inline .directions a:hover i {
            color: #fff; }
    body.low-contrast .footer .footer-main-menu ul li a,
    body.low-contrast .footer .footer-sub-menu ul li a {
      color: #fff; }
      body.low-contrast .footer .footer-main-menu ul li a:hover,
      body.low-contrast .footer .footer-sub-menu ul li a:hover {
        color: #fff; }
    body.low-contrast .footer .footer-main-menu ul li a {
      color: #fff;
      border-left: 0.0625rem solid white; }
    body.low-contrast .footer .footer-sub-menu ul li a {
      color: #fff; }
    body.low-contrast .footer .footer-social-links ul li a {
      color: #fff; }
      body.low-contrast .footer .footer-social-links ul li a:hover {
        color: #fff; }
    body.low-contrast .footer .copyrights .copy-text .cc-text a,
    body.low-contrast .footer .copyrights .copy-text .design-by a {
      color: #fff; }
      body.low-contrast .footer .copyrights .copy-text .cc-text a:hover,
      body.low-contrast .footer .copyrights .copy-text .design-by a:hover {
        color: #fff; }
  body.low-contrast .sponsers .affiliated .title h3 {
    border-color: #000; }
  body.low-contrast .breadcrumbs-wrapper nav .breadcrumbs li {
    color: #000000; }
    body.low-contrast .breadcrumbs-wrapper nav .breadcrumbs li a {
      color: #000; }
      body.low-contrast .breadcrumbs-wrapper nav .breadcrumbs li a:hover {
        color: #000; }
  body.low-contrast .page .page-title {
    background: #333; }
    body.low-contrast .page .page-title h3 {
      background: #000; }
  body.low-contrast .page .page-container .related-items .related-items-inner h2 {
    color: #000; }
  body.low-contrast .page .page-container .related-items .related-items-inner ul li span {
    color: #000; }
  body.low-contrast .page .page-container .related-items .related-items-inner ul li a {
    color: #000; }
    body.low-contrast .page .page-container .related-items .related-items-inner ul li a i {
      color: #000; }
  body.low-contrast .page .page-container .related-items .related-items-inner ul li:hover {
    background: #fff; }
  body.low-contrast .page .page-container .related-items .related-items-inner .show-all a {
    color: #000; }
    body.low-contrast .page .page-container .related-items .related-items-inner .show-all a i {
      color: #000; }
    body.low-contrast .page .page-container .related-items .related-items-inner .show-all a:hover {
      color: #000; }
  body.low-contrast .page .page-container .page-content .meta ul li {
    color: #000; }
  body.low-contrast .page .page-container .page-content .the-content .issue-content li span {
    color: #000; }
  body.low-contrast .page .page-container .page-content .the-content .issue-content li a {
    color: #000; }
    body.low-contrast .page .page-container .page-content .the-content .issue-content li a i {
      color: #000; }
    body.low-contrast .page .page-container .page-content .the-content .issue-content li a:hover {
      color: #000;
      text-decoration: underline; }
  body.low-contrast .page .page-container .page-content .the-content .issue-content.topnews {
    border-color: #000; }
    body.low-contrast .page .page-container .page-content .the-content .issue-content.topnews li a {
      color: #000; }
      body.low-contrast .page .page-container .page-content .the-content .issue-content.topnews li a i {
        color: #000; }
  body.low-contrast .page .page-container .page-content .the-content p {
    color: #000; }
    body.low-contrast .page .page-container .page-content .the-content p a {
      color: #000;
      text-decoration: underline; }
    body.low-contrast .page .page-container .page-content .the-content p .download-link {
      color: #000; }
      body.low-contrast .page .page-container .page-content .the-content p .download-link:after {
        color: #000; }
      body.low-contrast .page .page-container .page-content .the-content p .download-link:hover {
        color: #000; }
  body.low-contrast .page .page-container .page-content .the-content .page-number {
    color: #000; }
  body.low-contrast .page .page-container .page-content .the-content h2 {
    color: #000; }
  body.low-contrast .page .page-container .page-content .the-content h3 {
    border-color: #000;
    color: #000; }
  body.low-contrast .page .page-container .page-content .the-content h4 {
    color: #000; }
  body.low-contrast .page .page-container .page-content .the-content h5 {
    color: #000; }
  body.low-contrast .page .page-container .page-content .the-content blockquote {
    background: #F5F5F5; }
  body.low-contrast .page .page-container .page-content .the-content > ol li:before {
    color: #000; }
  body.low-contrast .page .page-container .page-content .the-content > ul > li:before {
    color: #000; }
  body.low-contrast .page .page-container .page-content .the-content .simplesearch-search-form label {
    color: #000; }
  body.low-contrast .page .page-container .page-content .the-content .simplesearch-search-form input[type="submit"] {
    background: #000;
    color: #fff;
    border: 2px solid #000; }
    body.low-contrast .page .page-container .page-content .the-content .simplesearch-search-form input[type="submit"]:hover {
      background: #fff;
      color: #000; }
  body.low-contrast .page .page-container .page-content .the-content .simplesearch-search-form input[type="text"] {
    border: 0.125rem solid #000;
    color: #000; }
    body.low-contrast .page .page-container .page-content .the-content .simplesearch-search-form input[type="text"]::placeholder {
      color: #000; }
    body.low-contrast .page .page-container .page-content .the-content .simplesearch-search-form input[type="text"]:focus {
      border-color: #000; }
  body.low-contrast .page .page-container .page-content .the-content hr {
    border-bottom-color: #000; }
  body.low-contrast .page .page-container .page-content .the-content .download-link {
    color: #000; }
    body.low-contrast .page .page-container .page-content .the-content .download-link:after {
      color: #000; }
    body.low-contrast .page .page-container .page-content .the-content .download-link:hover {
      color: #000; }
  body.low-contrast .page .page-container .page-content .page-accordion .expand-all button {
    color: #000; }
  body.low-contrast .page .page-container .page-content .page-accordion .accordion .accordion-item .accordion-title {
    color: #000;
    border-color: #000; }
    body.low-contrast .page .page-container .page-content .page-accordion .accordion .accordion-item .accordion-title:before {
      color: #000; }
  body.low-contrast .page .page-container .page-content .page-accordion .accordion .accordion-item .accordion-content .inner-accordion .inner-accordion-item .inner-accordion-title {
    color: #000; }
    body.low-contrast .page .page-container .page-content .page-accordion .accordion .accordion-item .accordion-content .inner-accordion .inner-accordion-item .inner-accordion-title:before {
      color: #000; }
  body.low-contrast .page .page-container .page-content .page-accordion .accordion .accordion-item .accordion-content .inner-accordion .inner-accordion-item .inner-accordion-content p {
    color: #000; }
    body.low-contrast .page .page-container .page-content .page-accordion .accordion .accordion-item .accordion-content .inner-accordion .inner-accordion-item .inner-accordion-content p a {
      color: #000;
      border-bottom: 0.0625rem solid #000; }
      body.low-contrast .page .page-container .page-content .page-accordion .accordion .accordion-item .accordion-content .inner-accordion .inner-accordion-item .inner-accordion-content p a:hover {
        border-color: #000;
        color: #000; }
  body.low-contrast .page .page-container .page-content .page-accordion .accordion .accordion-item .accordion-content .releases li a {
    border: 0.0625rem solid #000;
    color: #000; }
    body.low-contrast .page .page-container .page-content .page-accordion .accordion .accordion-item .accordion-content .releases li a:hover {
      background: #000;
      color: #fff; }
  body.low-contrast .page .page-container .page-content .page-accordion .accordion .accordion-item .accordion-content .press-releases li span {
    color: #000; }
  body.low-contrast .page .page-container .page-content .page-accordion .accordion .accordion-item .accordion-content .press-releases li a {
    color: #000; }
    body.low-contrast .page .page-container .page-content .page-accordion .accordion .accordion-item .accordion-content .press-releases li a i {
      color: #000; }
    body.low-contrast .page .page-container .page-content .page-accordion .accordion .accordion-item .accordion-content .press-releases li a:hover {
      color: #000; }
  body.low-contrast .page .page-container .sidebar .block .block-title:before {
    background: #333; }
  body.low-contrast .page .page-container .sidebar .block .block-title h3 {
    background: #000; }
  body.low-contrast .page .page-container .sidebar .block .block-content .plan .directory h5,
  body.low-contrast .page .page-container .sidebar .block .block-content .contact .directory h5 {
    border-color: #000; }
  body.low-contrast .page .page-container .sidebar .block .block-content .plan .download .download-link,
  body.low-contrast .page .page-container .sidebar .block .block-content .contact .download .download-link {
    color: #000; }
    body.low-contrast .page .page-container .sidebar .block .block-content .plan .download .download-link:after,
    body.low-contrast .page .page-container .sidebar .block .block-content .contact .download .download-link:after {
      color: #000; }
    body.low-contrast .page .page-container .sidebar .block .block-content .plan .download .download-link:hover,
    body.low-contrast .page .page-container .sidebar .block .block-content .contact .download .download-link:hover {
      color: #000; }
  body.low-contrast .page .page-container .sidebar .block .block-content .plan .directions a,
  body.low-contrast .page .page-container .sidebar .block .block-content .contact .directions a {
    color: #000; }
    body.low-contrast .page .page-container .sidebar .block .block-content .plan .directions a i,
    body.low-contrast .page .page-container .sidebar .block .block-content .contact .directions a i {
      color: #000; }
    body.low-contrast .page .page-container .sidebar .block .block-content .plan .directions a:hover,
    body.low-contrast .page .page-container .sidebar .block .block-content .contact .directions a:hover {
      color: #000; }
  body.low-contrast .page .page-container .sidebar .block .block-content .plan .email a,
  body.low-contrast .page .page-container .sidebar .block .block-content .plan .phone a,
  body.low-contrast .page .page-container .sidebar .block .block-content .contact .email a,
  body.low-contrast .page .page-container .sidebar .block .block-content .contact .phone a {
    color: #000; }
    body.low-contrast .page .page-container .sidebar .block .block-content .plan .email a:hover,
    body.low-contrast .page .page-container .sidebar .block .block-content .plan .phone a:hover,
    body.low-contrast .page .page-container .sidebar .block .block-content .contact .email a:hover,
    body.low-contrast .page .page-container .sidebar .block .block-content .contact .phone a:hover {
      color: #000;
      border-bottom: 0.0625rem solid #000; }
  body.low-contrast .page .page-container .sidebar .block .block-content .plan .email a,
  body.low-contrast .page .page-container .sidebar .block .block-content .contact .email a {
    border-bottom: 0.0625rem solid #000; }
  body.low-contrast .page .page-container .sidebar .block .block-content .related ul li a {
    border-bottom: 0.0625rem solid #000; }
    body.low-contrast .page .page-container .sidebar .block .block-content .related ul li a i {
      color: #000; }
    body.low-contrast .page .page-container .sidebar .block .block-content .related ul li a:hover {
      color: #000; }
    body.low-contrast .page .page-container .sidebar .block .block-content .related ul li a .date {
      color: #000; }
  body.low-contrast .page .page-container .sidebar .block .block-content .enquiries ul li {
    color: #000; }
    body.low-contrast .page .page-container .sidebar .block .block-content .enquiries ul li a {
      color: #000; }
      body.low-contrast .page .page-container .sidebar .block .block-content .enquiries ul li a.email {
        border-bottom: 0.0625rem solid #000; }
      body.low-contrast .page .page-container .sidebar .block .block-content .enquiries ul li a:hover {
        color: #000;
        border-color: #000; }
  body.low-contrast .page .page-container .sidebar .block .block-content .media-kit a {
    border-bottom: 0.0625rem solid #000;
    color: #000; }
    body.low-contrast .page .page-container .sidebar .block .block-content .media-kit a:hover {
      color: #000;
      border-color: #000; }
  body.low-contrast .page .page-container .sidebar .block .block-content .breast-menu li a:hover {
    color: #000; }
  body.low-contrast .page .page-container .sidebar .block .block-content .donate .donate-details {
    background: black; }
    body.low-contrast .page .page-container .sidebar .block .block-content .donate .donate-details:before {
      background: rgba(0, 0, 0, 0.8); }
  body.low-contrast .page .page-container .sidebar .block .block-content .more {
    color: #000; }
    body.low-contrast .page .page-container .sidebar .block .block-content .more i {
      color: #000; }
    body.low-contrast .page .page-container .sidebar .block .block-content .more:hover {
      color: #000; }
  body.low-contrast .page .page-container .sidebar .block .block-content .subscribe-form p {
    color: #000; }
  body.low-contrast .page .page-container .sidebar .block .block-content .subscribe-form form label {
    color: #000; }
  body.low-contrast .page .page-container .sidebar .block .block-content .subscribe-form form input {
    border-color: #000; }
  body.low-contrast .page .page-container .sidebar .block .block-content .subscribe-form form button {
    border: 0.125rem solid #000;
    color: #000; }
    body.low-contrast .page .page-container .sidebar .block .block-content .subscribe-form form button:hover {
      background: #000;
      color: #fff; }
  body.low-contrast .social-share span.title a .share-icon {
    background: url("../img/share-icon.svg") no-repeat center center; }
  body.low-contrast .social-share ul li a {
    color: #000; }
    body.low-contrast .social-share ul li a:hover {
      color: #000; }
  body.low-contrast .social-share.active {
    background: #EFEFEF; }
  body.low-contrast .surgery-dropmenu {
    background: #F5F5F5; }
    body.low-contrast .surgery-dropmenu h5 {
      color: #000; }
    body.low-contrast .surgery-dropmenu .surgery-menu-btn {
      color: #000;
      background: #fff; }
      body.low-contrast .surgery-dropmenu .surgery-menu-btn:hover {
        background: #000;
        color: #fff; }
    body.low-contrast .surgery-dropmenu .surgery-menu-content {
      background: #000; }
      body.low-contrast .surgery-dropmenu .surgery-menu-content ul li a {
        color: #fff; }
        body.low-contrast .surgery-dropmenu .surgery-menu-content ul li a:hover {
          color: #000;
          background: #fff; }
