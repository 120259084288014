.page-accordion {
  margin-bottom:rem-calc(40);
  .expand-all {
    text-align:right;
    margin-bottom:rem-calc(10);
    button {
      font-family: 'Roboto', sans-serif;
      font-weight: 900;
      color:#646464;
      font-size: rem-calc(26);
      line-height:rem-calc(38);
      letter-spacing:rem-calc(0.5);
      text-transform:uppercase;
      cursor:pointer;
      @include breakpoint(large) {
        font-size: rem-calc(14);
        line-height:rem-calc(26);
      }
    }

  }
  .accordion {
    list-style:none;
    .accordion-item {

      .accordion-title {
        border-left:none;
        border-right:none;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        color:#000;
        font-size: rem-calc(33);
        line-height:rem-calc(42);
        letter-spacing:rem-calc(0.1);
        border-color:#008DA8;
        padding-left:rem-calc(35);
        @include breakpoint(large) {
          font-size: rem-calc(22);
          line-height:rem-calc(30);
        }
        &[aria-expanded="true"] {
          border-bottom:none;
          background:none;
          &:before {
            transform:rotate(90deg);
          }
        }
        &:hover,
        &:focus {
          background:none;
        }
        &:before {
          font-family: 'icomoon';
          content: "\e909";
          margin-top:0;
          top:rem-calc(20);
          right:auto;
          left:0;
          transition:all 0.3s;
          font-size:rem-calc(16);
          color:#008DA8;
        }
      }
      .accordion-content {
        border:none;
        padding:rem-calc(20 0);
        img {
          width:100%;
          margin:rem-calc(0 0 30 0);
        }
        ul, ol {
          list-style:none;
          margin:rem-calc(0 0 23 0);

          li {
            font-size: rem-calc(30);
            line-height:rem-calc(46);
            padding:rem-calc(7 20);
            font-family: 'Roboto', sans-serif;
            position:relative;
            @include breakpoint(large) {
              font-size: rem-calc(19);
              line-height:rem-calc(30);
            }
          }
        }
        ul {
          > li {
            &:before {
              content:'•';
              left:0;
              color:#767676;
              position:absolute;
            }
          }
        }
        h2 {
          @include roboto(700);
          font-size: rem-calc(32);
          line-height:rem-calc(46);
          letter-spacing:rem-calc(0.1);
          margin:rem-calc(20 0);
          color:#1E1E1E;
          @include breakpoint(large) {
            font-size: rem-calc(28);
            line-height:rem-calc(29);
          }
        }

        h3 {
          @include roboto(700);
          font-size: rem-calc(32);
          line-height:rem-calc(46);
          letter-spacing:rem-calc(0.1);
          border-bottom:rem-calc(1) solid #008DA8;
          padding-bottom:rem-calc(10);
          margin-bottom:rem-calc(20);
          color:#1E1E1E;
          @include breakpoint(large) {
            font-size: rem-calc(22);
            line-height:rem-calc(30);
          }

          &.nounderline {
            border-bottom: 0;
            padding-bottom: 0;
          }
        }

        p {

          font-size: rem-calc(30);
          line-height:rem-calc(46);
          @include roboto(400);
          color: #1E1E1E;
          letter-spacing:rem-calc(0.3);
          @include breakpoint(large) {
            font-size: rem-calc(19);
            line-height: rem-calc(30);
          }

          strong {
            @include roboto(700);
          }
        }
        a {
          color:#0F6273;
          text-decoration:underline;
          font-size: rem-calc(30);
          line-height:rem-calc(46);
          @include roboto(400);
          letter-spacing:rem-calc(0.3);
          @include breakpoint(large) {
            font-size: rem-calc(19);
            line-height: rem-calc(30);
          }
        }
      }
    }
  }
}