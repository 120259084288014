.title-banner {
  background:#767676;
  .title {
    h1 {
      @include roboto(700);
      font-size:rem-calc(34);
      color:#fff;
      margin:0;
      padding:rem-calc(20 0);
    }
  }
}