.top-menu {
  height:rem-calc(30);
  background:#efefef;
  position: relative;
  z-index: 2;
  .top-menu-right {
    > ul {
      list-style:none;
      margin:0;
      padding:0;
      display:inline-block;
      line-height:rem-calc(30);
      > li {
        display:inline-block;
        line-height:rem-calc(30);
      }
    }
  }
  .top-menu-right {
    float:right;
    margin-right:0;
    height:rem-calc(30);
    .dropdown {
      font-size: 0; // hack to remove white space between inline-block elements

      > li {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        margin:0;
        padding:0;
        &:last-child {
          > a {
            margin-right:0;
          }
        }
        > a {
          color: #1e1e1e;
          display: block;
          height:rem-calc(30);
          line-height:rem-calc(22);
          margin: rem-calc(0 17);
          padding:rem-calc(0 7 0 0);
          font-size: rem-calc(14);
          letter-spacing:rem-calc(0.3);
          &:hover {
            color: #4e4e4e;
          }
        }
        .submenu {
          z-index:9999 !important;
          li {
            a {
              color:#333;
              font-size: rem-calc(14);
            }
            &.divider {
              border-bottom:rem-calc(1) solid #eee;
              display:block;
            }
          }
        }
        &.is-dropdown-submenu-parent {
          > a {
            position:relative;
            &:after {
              content: "\e906";
              font-family:'icomoon';
              border:none;
              position:absolute;
              top:rem-calc(4);
              right:0;
              font-size:rem-calc(5);
            }
          }
          &:hover {
            > a {
              &:after {
                content: "\e905";
              }
            }
          }
        }
      }
    }
  }
}
.clear {
  clear:both;
}
.header {
  padding:rem-calc(10 0);
  position: relative;
  z-index: 1;
  background:#fff;
  border-top:rem-calc(8) solid #BFDB08;
  border-bottom:rem-calc(1) solid #D2D2D2;
  @include breakpoint(large) {
    border-top:none;
  }
  .logo {
    padding:rem-calc(20 0 20 30);
    float:left;
    @include breakpoint(large) {
      padding:0;
    }

    a {
      img {
        width:rem-calc(150);
        @include breakpoint(large) {
          width:rem-calc(150);
        }

      }
    }
  }
  .mobile-menu-btn {
    position:absolute;
    top:rem-calc(50);
    right:rem-calc(50);

    .menu-button {
      display:inline-block;
      .hamburger {
        width: rem-calc(50);
        height: rem-calc(50);
        position: relative;
        &:hover {
          span {
            border-color:#BFDB08;
          }
        }

        span {
          border-top: rem-calc(3) solid #3b3b41;
          display: block;
          opacity: 1;
          position: absolute;
          width: 100%;
          top: 0;
          -webkit-transition: all .6s ease;
          -moz-transition: all .6s ease;
          -o-transition: all .6s ease;
          transition: all .6s ease;


          &:first-of-type {
            top: 0;
          }
          &:nth-of-type(2) {
            top: rem-calc(14);
          }
          &:last-of-type {
            top: rem-calc(28);
          }

          &:after {
            display: none;
          }
        }
      }
    }
  }
  .the-mobile-menu {
    clear:both;
    background:#F5F5F5;
    display:none;
    position:absolute;
    top:rem-calc(116);
    left:0;
    right:0;
    height:100vh;
    overflow-y:auto;
    @include breakpoint(large) {
      clear:none;
      background:none;
      display:block;
      position:static;
      height:auto;
      overflow-y:visible;
      float:right;
    }
    > ul {
      list-style:none;
      margin:0;
      padding:rem-calc(40 50);
      line-height:rem-calc(75);
      @include breakpoint(large) {
        padding:0;
      }
      > li {

        @include breakpoint(large) {
          display:inline-block;
          padding:rem-calc(0 25);
          line-height:1;
          border-right:rem-calc(1) solid #D2D2D2;
          position:relative;
          z-index:1;
        }
        &:last-child {
          @include breakpoint(large) {
            padding-right:0;
            border-right:none;
          }
        }
        &.is-dropdown-submenu-parent {
          position:relative;
          > a {
            &:after {
              content: "\e909";
              font-family:'icomoon';
              font-size:rem-calc(26);
              position:absolute;
              right:0;
              top:rem-calc(12);
              transition:all 0.1s linear;
              @include breakpoint(large) {
                display:none;
              }
            }
          }
        }
        > a {
          @include roboto(700);
          font-size:rem-calc(31);
          letter-spacing:rem-calc(0.2);
          color:#3B3B41;
          padding-bottom:rem-calc(3);
          &:hover {
            @include breakpoint(large) {
              border-bottom:rem-calc(2) solid #008DA8;
            }
          }
          @include breakpoint(large) {
            font-size:rem-calc(17);
          }
        }
        &.active,
        &.is-active {
          a {
            @include breakpoint(large) {
              border-bottom:rem-calc(2) solid #008DA8;
            }
          }
        }
        &.is-active {
          a {
            &:after {
              transform:rotate(90deg);
            }
          }
        }
        .submenu {
          position:static;
          border:none;
          list-style:none;
          background:none;
          margin:0;
          @include breakpoint(large) {
            position:absolute;
            min-width:rem-calc(270);
            padding:rem-calc(30 30 15 30);
            z-index:999 !important;
            background:rgba(165,165,165,0.95);
            margin:0;
            top:rem-calc(56);
            left:0;

          }
          li {
            line-height:1;
            padding:0;
            a {
              display:block;
              font-family: 'Roboto', sans-serif;
              font-weight:500;
              color:#3B3B41;
              padding:0;
              font-size:rem-calc(26);
              line-height:rem-calc(60);
              border-bottom:rem-calc(1) solid transparent;
              letter-spacing: rem-calc(0.2);
              white-space: nowrap;
              margin-bottom:rem-calc(15);
              @include breakpoint(large) {
                display:inline-block;
                color:#fff;
                padding:0;
                font-size:rem-calc(16);
                line-height:rem-calc(21);
                border-color:transparent;

              }
              &:hover {
                @include breakpoint(large) {
                  border-bottom: rem-calc(1) solid #FFFFFF;
                }
              }
            }
            &.active {
              a {
                @include breakpoint(large) {
                  border-bottom: rem-calc(1) solid #FFFFFF;
                }
              }
            }
          }
        }
      }
    }
  }
}

