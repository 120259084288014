.page-container {

  @include breakpoint(large) {
    padding:rem-calc(0 20);
  }
  > .row {
    display: block;
    @include breakpoint(large) {
      display: flex;
    }
  }
  .page-content {
    padding:rem-calc(20);
    @include breakpoint(large) {
      padding:rem-calc(40 0);
    }
    &.right-space {
      @include breakpoint(large) {
        margin-right:rem-calc(100);
      }
    }
    .the-content {
      img {
        width:100%;
        margin:rem-calc(0 0 30 0);
      }
      ul, ol {
        list-style:none;
        margin:rem-calc(0 0 23 0);

        li {
          font-size: rem-calc(30);
          line-height:rem-calc(46);
          padding:rem-calc(7 20);
          font-family: 'Roboto', sans-serif;
          position:relative;
          @include breakpoint(large) {
            font-size: rem-calc(19);
            line-height:rem-calc(30);
          }
        }
      }
      ul {
        > li {
          &:before {
            content:'•';
            left:0;
            color:#767676;
            position:absolute;
          }
        }
      }
      h2 {
        @include roboto(700);
        font-size: rem-calc(32);
        line-height:rem-calc(46);
        letter-spacing:rem-calc(0.1);
        margin:rem-calc(40 0 20 0);
        color:#1E1E1E;
        @include breakpoint(large) {
          font-size: rem-calc(28);
          line-height:rem-calc(29);
        }
      }

      h3 {
        @include roboto(700);
        font-size: rem-calc(32);
        line-height:rem-calc(46);
        letter-spacing:rem-calc(0.1);
        border-bottom:rem-calc(1) solid #008DA8;
        padding-bottom:rem-calc(10);
        margin-bottom:rem-calc(20);
        color:#1E1E1E;
        @include breakpoint(large) {
          font-size: rem-calc(22);
          line-height:rem-calc(30);
        }

        &.nounderline {
          border-bottom: 0;
          padding-bottom: 0;
        }
      }

      p {

        font-size: rem-calc(30);
        line-height:rem-calc(46);
        @include roboto(400);
        color: #1E1E1E;
        letter-spacing:rem-calc(0.3);
        @include breakpoint(large) {
          font-size: rem-calc(19);
          line-height: rem-calc(30);
        }

        strong {
          @include roboto(700);
        }
        a {
          color:#0F6273;
          text-decoration:underline;
        }
      }
      a {
        color:#0F6273;
        text-decoration:underline;
        font-size: rem-calc(30);
        line-height:rem-calc(46);
        @include roboto(400);
        letter-spacing:rem-calc(0.3);
        @include breakpoint(large) {
          font-size: rem-calc(19);
          line-height: rem-calc(30);
        }
      }
    }
  }


  .sidebar {
    padding-top:rem-calc(40);
    margin:rem-calc(0 -20);
    @include breakpoint(large) {
      margin:rem-calc(0 0 50 0);
    }
    .widget {
      padding:rem-calc(30 60);
      position:relative;
      z-index: 2;
      background:#EFEFEF;
      @include breakpoint(large) {
        padding:rem-calc(30 25 30 25);
      }
      &.with-border {
        padding-bottom:0;
        &:after {
          content:'';
          height:rem-calc(2);
          width:100%;
          background:#fff;
          display:block;
          margin-top:rem-calc(30);
        }
      }
      .widget-title {
        h2 {
          @include roboto(700);
          font-size:rem-calc(38);
          line-height:rem-calc(25);
          padding-bottom:rem-calc(25);
          border-bottom:rem-calc(2) solid #008DAA;
          margin:0;
          @include breakpoint(large) {
            font-size:rem-calc(20);
            padding-bottom:rem-calc(15);
          }
        }
      }
      .widget-content {
        ul {
          list-style:none;
          margin:0;
          padding:0;
          li {
            padding:rem-calc(15 0);
            a {
              @include roboto(500);
              font-size:rem-calc(31);
              line-height:rem-calc(40);
              padding-left:rem-calc(30);
              color:#000;
              position:relative;
              display:block;
              @include breakpoint(large) {
                padding-left:rem-calc(20);
                font-size:rem-calc(18);
                line-height:rem-calc(23);
              }
              &:before {
                font-family: 'icomoon';
                content: "\e909";
                margin-top:0;
                position:absolute;
                left:0;
                transition:all 0.3s;
                font-size:rem-calc(22);
                color:#008DA8;
                display:none;
                @include breakpoint(large) {
                  font-size:rem-calc(12);
                }
              }
              &:hover {
                color:#0F6273;
                &:before {
                  display:block;
                }
              }
            }
            &.active {
              a {
                &:before {
                  display:block;
                }
              }
            }
          }
        }
        h3 {
          font-size:rem-calc(35);
          line-height:rem-calc(36);
          color:#0F6273;
          @include roboto(700);
          margin-bottom:rem-calc(20);
          @include breakpoint(large) {
            font-size:rem-calc(25);
            line-height:rem-calc(26);
          }
        }
        p {
          font-size:rem-calc(26);
          line-height:rem-calc(34);
          color:#1E1E1E;
          @include roboto(400);
          @include breakpoint(large) {
            font-size:rem-calc(16);
            line-height:rem-calc(24);
          }
          a {
            text-decoration:underline;
          }
        }
        .phone {
          display:table;
          span {
            display:table-cell;
            font-size:rem-calc(26);
            line-height:rem-calc(34);
            color:#1E1E1E;
            @include roboto(500);
            &:first-child {
              padding-right:rem-calc(12);
            }
            @include breakpoint(large) {
              font-size:rem-calc(16);
              line-height:rem-calc(24);
            }
          }
        }
      }
    }
  }
}