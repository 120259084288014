.breadcrumbs-wrapper {
  background:#fff;
  padding:rem-calc(40 20);
  display:none;
  @include breakpoint(large) {
    display:block;
    padding:rem-calc(30 0);
  }
  .breadcrumbs {
    display:block;
    margin:0;
    ul {
      list-style:none;
      margin:0;
      padding:0;
      font-size:0;
      li {
        @include roboto(700);
        font-size:rem-calc(15);
        line-height:rem-calc(17);
        color:#3B3B41;
        letter-spacing:rem-calc(0.4);
        a {
          color:#767676;
        }
        > span {

        }
      }
    }
  }
}

