.footer {
  padding:rem-calc(50 20 70 20);
  background:#f5f5f5;
  @include breakpoint(large) {
    padding:rem-calc(50 0 70 0);
  }
  .footer-logo {
    padding-top:rem-calc(50);
    @include breakpoint(large) {
      padding-top:0;
      text-align:right;
    }
    img {
      width:rem-calc(250);
      @include breakpoint(large) {
        width:rem-calc(160);
      }
    }
  }
  .footer-links {
    border-top:rem-calc(2) solid #fff;

    padding-top:rem-calc(50);
    margin-top:rem-calc(50);
    @include breakpoint(large) {
      margin-top:rem-calc(35);
      padding-top:rem-calc(15);
      display:flex;
      flex-wrap:nowrap;
      flex-direction: row;
    }
    ul {
      list-style:none;
      margin:0;
      padding:0;
      column-count: 2;
      display:inline;
      flex-grow:2;
      @include breakpoint(large) {

        column-count: 1;
      }
      li {
        display:block;
        @include breakpoint(large) {
          display:inline-block;
        }
        a {
          @include roboto(400);
          font-size:rem-calc(25);
          line-height:rem-calc(65);
          color:#1E1E1E;
          @include breakpoint(large) {
            margin-right:rem-calc(20);
            font-size:rem-calc(14);
            line-height:rem-calc(24);
          }
          &:hover {
            color:#636363;
          }
        }
      }
    }
    span {
      padding-top:rem-calc(50);
      @include roboto(400);
      font-size:rem-calc(22);
      line-height:rem-calc(38);
      color:#1E1E1E;
      display:block;
      @include breakpoint(large) {
        padding-top:0;
        display:inline;
        font-size:rem-calc(14);
        line-height:rem-calc(24);
        align-self:flex-end;
      }
    }
  }
}