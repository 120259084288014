body {
  &.low-contrast {
    filter: grayscale(100%) contrast(50%);
    &.surgery-expanded {
      .surgery-dropmenu {
        .surgery-menu-btn {
          background:#000;
          color:#fff;
        }
      }
    }
    img {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
    #mobile-header {
      .mobile-header {
        border-top:rem-calc(8) solid #000;
      }
      .mobile-menu {
        .mobile-menu-header {
          background:#000;
          border-top:rem-calc(8) solid #000;
          ul {
            li {
              a {
                color:#fff;
              }
              &.pink {
                &:hover,
                &.active {
                  a {
                    border-color:#fff;
                  }
                }
              }
              &.blue {
                &:hover,
                &.active {
                  a {
                    border-color:#fff;
                  }
                }
              }
              &.grey {
                &:hover,
                &.active {
                  a {
                    border-color:#fff;
                  }
                }
              }
              &.green {
                &:hover,
                &.active {
                  a {
                    border-color:#fff;
                  }
                }
              }
            }
          }
        }
        .mobile-menu-search {
          .search-form {
            form {
              input {
                border:rem-calc(2) solid #000;
                color:#000;
                &::placeholder {
                  color:#000;
                }
                &:focus {
                  border-color:#000;
                }
              }
              button {
                i {
                  color:#000;
                }
              }
            }
          }
        }
        .mobile-menu-list {
          .accordion {
            .accordion-item {
              .accordion-title {
                color:#000;
                &[aria-expanded="true"] {
                  border-bottom:rem-calc(6) solid #333;
                  &:before {
                    content: "\ea0a";
                  }
                }
                &:hover {
                  background:#000;
                  color:#fff;
                }
              }
              .accordion-content {
                ul {
                  li {
                    a {
                      color:#000;
                    }
                  }
                }
              }
            }
          }
        }
        .mobile-menu-footer {
          background:#000;
          ul {
            &.search-list {
              li {
                a {
                  &:hover {
                    color:#fff;
                  }
                }
              }
            }
            &.social-links {
              li {
                a {
                  &:hover {
                    color:#fff;
                  }
                }
              }
            }
            &.dropdown {
              > li {
                > a {
                  color: #fff;
                  &:hover {
                    color:#fff;
                  }
                }
                .submenu {
                  li {
                    a {
                      color:#000;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .top-menu {
      background:#000;
      .top-menu-left,
      .top-menu-right {
        .tabs-menu {
           li {
             &.pink,
             &.green,
             &.blue,
             &.grey {
               border-color:#eee;
             }
             &.active,
             &:hover {
               a {
                 background:#333;
               }
             }

           }
        }
        > ul {
          > li {
            > a {
              color:#fff;
              &:hover {
                color:#fff;
              }
            }
            .goog-te-menu-value {
              span {
                color:#fff;
              }
              &:after {
                color:#fff;
              }

            }

          }
        }
      }
    }
    .header {
      .logo {}
      .search-container {
        .search-list {
          li {
            &.donate-button {
              a {
                background:#000;
              }
            }
            &.search-form {
              input {
                border-color:#000;
              }
              button {
                i {
                  color:#000;
                }
              }
            }
          }
        }
        .social-links {
          li {
            a {
              color:#000;
            }
          }
        }
      }
    }
    .mega-menu-bar {
      background:#000;
      .mega-menu {
        > ul {
          > li {
            > a {
              color:#fff;
              &:after {
                color:#fff;
              }
            }
            &.has-sub-menu {
              .sub-menu {
                h2 {
                  color:#000;
                  border-color:#000;
                }
              }
              .mega-sub-menu {
                > ul {
                  > li {
                    > a {
                      color:#000;
                    }
                  }
                }
              }
            }
          }
        }

      }
    }
    .slider {
      .slide {
        .slide-details {
          background: rgba(0, 0, 0, 1);
          &:after {
            background: rgba(0, 0, 0, 0.8);
          }
          .slide-details-inner {
            .details {
              .cta-button {
                &:hover {
                  color:#000;
                }
              }
            }
          }
        }
      }
    }
    .static-slide {
      .slide-details {
        background: rgba(0, 0, 0, 1);
        &:after {
          background: rgba(0, 0, 0, 0.8);
        }
        .slide-details-inner {
          .details {
            .cta-button {
              &:hover {
                color:#000;
              }
            }
          }
        }
      }
    }
    .main-sections {
      .section-box {
        a {
          color:#000;
          span {
            color:#000;
          }
          .section {
            background:#000;
          }
          &:hover {
            .section {
              background:#000;
            }
          }
        }
      }
    }
    .care-slider {
      .slider-title {
        h3 {
          color:#000;
          border-color:#000;
        }
      }
      .slides {
        .slide {
          .slide-inner {
            .slide-inner-text {
              &:after {
                background: rgba(0, 0, 0, 0.8);
              }
            }
          }
        }
      }
      .slick-prev,
      .slick-next {
        background:#000000;
        &:before {
          color:#fff;
          opacity:1;
        }
      }
    }

    .care-slider-mobile {
      .slider-title {
        h3 {
          color:#000;
          border-color:#000;
        }
      }
      .mobile-slides {
        .slide {
          a {
            .slide-inner {
              .slide-inner-text {
                background:#000;
                &:after {
                  background:rgba(0,0,0,0.5);
                }
              }
              &.slide-green {
                .slide-inner-text {
                  &:after {
                    background: rgba(0, 0, 0, 0.8);
                  }
                }
              }
              &.slide-pink {
                .slide-inner-text {
                  &:after {
                    background: rgba(0, 0, 0, 0.8);
                  }
                }
              }
              &.slide-navy {
                .slide-inner-text {
                  &:after {
                    background: rgba(0, 0, 0, 0.8);
                  }
                }
              }
            }
          }
        }
      }
      .slick-prev,
      .slick-next {
        border: rem-calc(4) solid #000;
        background:#fff;
        color: #000;
        &.slick-disabled,
        &:hover {
          background:#fff;
          color: #000;
        }
        &:before {
          color:#000;
        }
      }
    }
    .last-news {
      .block-title {
        h3 {
          color:#000;
          border-color:#000;
        }
      }
      .news {
        .single-post {
          a {
            &:hover {
              h3,
              p {
                color:#000;
              }
            }
            .category {
              h5{
                &:after {
                  background: rgba(0, 0, 0, 0.8);
                }
              }
            }
          }
        }
      }
    }
    .newsletter-block {
      background:#000000;
      .newsletter-link {
        h3 {
          a {
            color:#fff;
            i {
              color:#000;
              background:#fff;
              border-color:#000;
            }
          }
        }
      }
      .newsletter-form,
      #campignerForm  {
        p,
        label {
          color:#fff;
        }
      }
    }
    .social-block {
      .block-title {
        h3 {
          color:#000;
          border-color:#000;
        }
      }
      .tweets {
        .tweet {
          .tweet-icon {
            i {
              background: #000;
            }
          }
        }
      }
      .slick-dots {
        li {
          &.slick-active {
            button {
              &:before {
                background:#000;
                border-color:#000;
              }
            }
          }
        }
      }
    }
    .footer {
      background:#000;
        .contact-details {
          border-bottom:rem-calc(1) solid #000;
          .small-inline {
            .email {
              a {
                color:#fff;
                &:hover {
                  color:#fff;
                }
              }
            }
            .phone-number {
              a {
                color:#fff;
                &:hover {
                  color:#fff;
                }
              }
            }
            .directions {
              a {
                color:#fff;
                i {
                  color:#fff;
                }
                &:hover {
                  color:#fff;
                  i {
                    color:#fff;
                  }
                }
              }
            }
          }
        }
        .footer-main-menu,
        .footer-sub-menu {
          ul {
            li {
              a {
                color:#fff;
                &:hover {
                  color:#fff;
                }
              }
            }
          }
        }
        .footer-main-menu {
          ul {
            li {
              a {
                color:#fff;
                border-left:rem-calc(1) solid rgb(255,255,255);
              }
            }
          }
        }
        .footer-sub-menu {

          ul {
            li {
              a {
                color:#fff;
              }
            }
          }
        }
        .footer-social-links {

          ul {
            li {
              a {
                color:#fff;
                &:hover {
                  color:#fff;
                }
              }
            }
          }
        }
        .copyrights {
          .copy-text {
            .cc-text,
            .design-by {
              a {
                color:#fff;
                &:hover {
                  color:#fff;
                }
              }
            }
          }
        }
    }
    .sponsers {
      .affiliated {
        .title {
          h3 {
            border-color:#000;
          }
        }
      }
    }
    .breadcrumbs-wrapper {
      nav {
        .breadcrumbs {
          li {
            color:#000000;
            a {
              color:#000;
              &:hover {
                color:#000;
              }
            }
          }
        }
      }
    }
    .page {
      .page-title {
        background:#333;
        h3 {
          background:#000;
        }
      }
      .page-container {
        .related-items {
          .related-items-inner {
            h2 {
              color:#000;
            }
            ul {
              li {
                span {
                  color:#000;
                }
                a {
                  color:#000;
                  i {
                    color:#000;
                  }
                }
                &:hover {
                  background:#fff;
                }
              }
            }
            .show-all {
              a {
                color:#000;
                i {
                  color:#000;
                }
                &:hover {
                  color:#000;
                }
              }
            }
          }
        }
        .page-content {
          .meta {
            ul {
              li {
                color:#000;
              }
            }
          }
          .the-content {
            .issue-content {
              li {
                span {
                  color:#000;
                }
                a {
                  color:#000;
                  i {
                    color:#000;
                  }
                  &:hover {
                    color:#000;
                    text-decoration:underline;
                  }
                }
              }

              &.topnews {
                border-color:#000;

                li {
                  a {
                    color:#000;
                    i {
                      color:#000;
                    }
                  }
                }
              }
            }
            p {
              color:#000;
              a {
                color:#000;
                text-decoration:underline;
              }
              .download-link {
                color:#000;
                &:after {
                  color:#000;
                }
                &:hover {
                  color:#000;
                }

              }
            }

            .page-number {
              color:#000;
            }
            h2 {
              color:#000;
            }
            h3 {
              border-color:#000;
              color:#000;
            }
            h4 {
              color:#000;
            }
            h5 {
              color:#000;
            }
            blockquote {
              background:#F5F5F5;
            }

            > ol {
              li {
                &:before {
                  color:#000;
                }
              }
            }

            > ul {
              > li {
                &:before {
                  color:#000;
                }
              }
            }



            .simplesearch-search-form {
              label {
                color:#000;
              }

              input[type="submit"] {
                background:#000;
                color:#fff;
                border: 2px solid #000;

                &:hover {
                  background:#fff;
                  color:#000;
                }
              }

              input[type="text"] {
                border:rem-calc(2) solid #000;
                color:#000;
                &::placeholder {
                  color:#000;
                }
                &:focus {
                  border-color:#000;
                }

              }
            }

            hr {
              border-bottom-color: #000;
            }
            .download-link {
              color:#000;
              &:after {
                color:#000;
              }
              &:hover {
                color:#000;
              }

            }
          }
          .page-accordion {
            .expand-all {
              button {
                color:#000;
              }
            }
            .accordion {
              .accordion-item {
                .accordion-title {
                  color:#000;
                  border-color:#000;
                  &:before {
                    color:#000;
                  }
                }
                .accordion-content {
                  .inner-accordion {
                    .inner-accordion-item {
                      .inner-accordion-title {
                        color:#000;
                        &:before {
                          color:#000;
                        }
                      }
                      .inner-accordion-content {
                        p {
                          color:#000;
                          a {
                            color:#000;
                            border-bottom:rem-calc(1) solid #000;
                            &:hover {
                              border-color:#000;
                              color:#000;
                            }
                          }
                        }
                      }
                    }
                  }
                  .releases {
                    li {
                      a {
                        border:rem-calc(1) solid #000;
                        color:#000;
                        &:hover {
                          background:#000;
                          color:#fff;
                        }
                      }
                    }
                  }
                  .press-releases {
                    li {
                      span {
                        color:#000;
                      }
                      a {
                        color:#000;
                        i {
                          color:#000;
                        }
                        &:hover {
                          color:#000;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .sidebar {
          .block {
            .block-title {
              &:before {
                background:#333;
              }
              h3 {
                background:#000;
              }
            }
            .block-content {
              .plan,
              .contact {
                .directory {
                  h5 {
                    border-color:#000;
                  }
                }
                .download {
                  .download-link {
                    color:#000;
                    &:after {
                      color:#000;
                    }
                    &:hover {
                      color:#000;
                    }
                  }
                }
                .directions {
                  a {
                    color:#000;
                    i {
                      color:#000;
                    }
                    &:hover {
                      color:#000;
                    }
                  }
                }
                .email,
                .phone {
                  a {
                    color:#000;
                    &:hover {
                      color:#000;
                      border-bottom:rem-calc(1) solid #000;
                    }
                  }
                }
                .email {
                  a {
                    border-bottom:rem-calc(1) solid #000;
                  }
                }
              }
              .related {
                ul {
                  li {
                    a {
                      border-bottom:rem-calc(1) solid #000;
                      i {
                        color:#000;
                      }
                      &:hover {
                        color:#000;
                      }
                      .date {
                        color:#000;
                      }
                    }
                  }
                }
              }

              .enquiries {

                ul {
                  li {
                    color:#000;
                    a {
                      color:#000;

                      &.email {
                        border-bottom:rem-calc(1) solid #000;
                      }
                      &:hover {
                        color:#000;
                        border-color:#000;
                      }
                    }
                  }
                }
              }
              .media-kit {
                a {
                  border-bottom:rem-calc(1) solid #000;
                  color:#000;
                  &:hover {
                    color:#000;
                    border-color:#000;
                  }
                }
              }
              .breast-menu {
                li {
                  a {
                    &:hover {
                      color:#000;
                    }
                  }
                }
              }
              .donate {
                .donate-details {
                  background: rgba(0, 0, 0, 1);
                  &:before {
                    background: rgba(0, 0, 0, 0.8);
                  }
                }
              }
              .more {
                color:#000;
                i {
                  color:#000;
                }
                &:hover {
                  color:#000;
                }
              }
              .subscribe-form {
                p {
                  color:#000;
                }
                form {
                  label {
                    color:#000;
                  }
                  input {
                    border-color:#000;
                  }
                  button {
                    border:rem-calc(2) solid #000;
                    color:#000;
                    &:hover {
                      background:#000;
                      color:#fff;
                    }
                  }
                }
              }


            }
          }
        }
      }
    }
    .social-share {
      span {
        &.title {
          a {
            .share-icon {
              background:url('../img/share-icon.svg') no-repeat center center;
            }
          }
        }
      }

      ul {
        li {
          a {
            color:#000;
            &:hover {
              color:#000;
            }
          }
        }
      }
      &.active {
        background:#EFEFEF;
      }
    }
    .surgery-dropmenu {
      background:#F5F5F5;
      h5 {
        color:#000;
      }
      .surgery-menu-btn {
        color:#000;
        background:#fff;
        &:hover {
          background:#000;
          color:#fff;
        }
      }
      .surgery-menu-content {
        background:#000;
        ul {
          li {
            a {
              color:#fff;
              &:hover {
                color:#000;
                background:#fff;
              }
            }
          }
        }
      }
    }
  }
}